import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';

import AppAccordion from '../../../components/AppAccordion';
import AppButton from '../../../components/AppButton';
import AppCard from '../../../components/AppCard';
import AppDivider from '../../../components/AppDivider';
import AppPageLayout from '../../../hocs/AppPageLayout';
import HashScroll from '../../../hocs/HashScroll';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { selectApp } from '../../../redux/selectors/appSelectors';
import { selectAuth } from '../../../redux/selectors/authSelectors';
import { selectShiftSummary } from '../../../redux/selectors/shiftSummarySelectors';
import { Colors } from '../../../theme/colors';
import { decimalCalculations } from '../../../utils';
import ConsolidatedShiftSummaryTabs from '../components/ConsolidatedShiftSummaryTabs';
import PaymentsRowInfo from '../components/PaymentsRowInfo';
import RowInfo from '../components/RowInfo';
import { getConsolidatedShiftSummary } from '../redux/shiftSummarySlice';
import { formatValue } from '../utils/common.utils';
import { getPaymentTypes } from '../utils/formatting.utils';
import ConsolidatedShiftSummaryFilterView from '../views/ConsolidatedShiftSummaryFilterView';
import CategorySalesRowInfo from '../components/CategorySalesRowInfo';

function ConsolidatedShiftSummaryPage() {
  const dispatch = useAppDispatch();

  const { reportStartDate, reportEndDate } = useAppSelector((state) => ({
    reportStartDate: dayjs(selectApp(state).reportStartDate)
      .startOf('day')
      .format('YYYY-MM-DD HH:mm:ss'),
    reportEndDate: dayjs(selectApp(state).reportEndDate)
      .endOf('day')
      .format('YYYY-MM-DD HH:mm:ss'),
  }));

  const { consolidatedShiftSummaryData, refreshing } =
    useAppSelector(selectShiftSummary);

  const { restaurant } = useAppSelector(selectAuth);

  const [accordionsExpanded, setAccordionsExpanded] = useState({
    shiftSummary: false,
    sales: false,
    categorySales: false,
    payments: false,
    serviceCharge: false,
    taxes: false,
    discounts: false,
    voids: false,
    cashSummary: false,
    refunds: false,
  });

  const handleOpenAll = () => {
    setAccordionsExpanded({
      shiftSummary: true,
      sales: true,
      categorySales: true,
      payments: true,
      serviceCharge: true,
      taxes: true,
      discounts: true,
      voids: true,
      cashSummary: true,
      refunds: true,
    });
  };

  const handleCloseAll = () => {
    setAccordionsExpanded({
      shiftSummary: false,
      sales: false,
      categorySales: false,
      payments: false,
      serviceCharge: false,
      taxes: false,
      discounts: false,
      voids: false,
      cashSummary: false,
      refunds: false,
    });
  };

  const getConsolidatedShiftSummaryData = useCallback(async () => {
    await dispatch(
      getConsolidatedShiftSummary({
        date_from: reportStartDate ?? undefined,
        date_to: reportEndDate ?? undefined,
        offset: restaurant?.timeZoneValue,
      }),
    );
  }, [dispatch, reportStartDate, reportEndDate, restaurant?.timeZoneValue]);

  useEffect(() => {
    getConsolidatedShiftSummaryData();
  }, [getConsolidatedShiftSummaryData]);

  const currencyValue = (value: number) => {
    return decimalCalculations(value).toMoney();
  };

  const currencyValueCents = (value: number) => {
    return decimalCalculations(value).toDecimal().toMoney();
  };

  const currencyValueSymbol = (value: number) => {
    return decimalCalculations(value).toMoney(restaurant?.posCurrencySymbol);
  };

  const currencyValueSymbolCents = (value: number) => {
    return decimalCalculations(value)
      .toDecimal()
      .toMoney(restaurant?.posCurrencySymbol);
  };

  const categorySalesSummary = () => {
    const categoryDetails =
      consolidatedShiftSummaryData?.orderSummary?.categoryDetails.data ?? [];

    // check total gst have more than 0 value
    if (
      (consolidatedShiftSummaryData?.orderSummary?.categoryDetails.totalGST ??
        0) > 0
    ) {
      return categoryDetails.map((detail) => (
        <CategorySalesRowInfo
          key={detail.categoryRefId}
          categoryName={detail.categoryName}
          itemSalesCount={detail.noOfSales}
          grossAmount={decimalCalculations(detail.grossTotal).toMoney()}
          discountAmount={decimalCalculations(detail.discounts).toMoney()}
          netGstAmount={decimalCalculations(detail.gst).toMoney()}
          refundedGstAmount={decimalCalculations(detail.refundedGST).toMoney()}
          refundsAmount={decimalCalculations(detail.refunds).toMoney()}
          refreshing={refreshing}
        />
      ));
    } else {
      return categoryDetails.map((detail) => (
        <CategorySalesRowInfo
          key={detail.categoryRefId}
          categoryName={detail.categoryName}
          itemSalesCount={detail.noOfSales}
          grossAmount={decimalCalculations(detail.grossTotal).toMoney()}
          discountAmount={decimalCalculations(detail.discounts).toMoney()}
          refundsAmount={decimalCalculations(detail.refunds).toMoney()}
          refreshing={refreshing}
        />
      ));
    }
  };

  const netPaymentCardSummary = () => {
    const cards =
      consolidatedShiftSummaryData?.paymentsSummary?.netPayments.cards.data ??
      [];
    return cards.map((card) => (
      <PaymentsRowInfo
        key={card.cardBrand}
        label={getPaymentTypes(card.cardBrand)}
        processedValue={currencyValueCents(card.processedAmount)}
        processedQty={`${card.processedCount}`}
        refundsValue={`-${currencyValueCents(card.refundedAmount)}`}
        refundsQty={`${card.refundedCount}`}
        netValue={currencyValueCents(card.netAmount)}
        labelSx={{
          marginLeft: 1,
          color: Colors.fontColorSecondary,
          fontWeight: 400,
        }}
        valueSx={{ color: Colors.fontColorSecondary, fontWeight: 400 }}
        qtySx={{ color: Colors.fontColorSecondary, fontWeight: 400 }}
        refreshing={refreshing}
      />
    ));
  };

  const netPaymentCustomSummary = () => {
    const customs =
      consolidatedShiftSummaryData?.paymentsSummary?.netPayments?.custom
        ?.data ?? [];
    return customs.map((custom) => (
      <PaymentsRowInfo
        label={`${custom.paymentMethodName}`}
        processedValue={currencyValueCents(custom.processedAmount)}
        processedQty={String(custom.processedCount)}
        refundsValue={`-${currencyValueCents(custom.refundedAmount)}`}
        refundsQty={String(custom.refundedCount)}
        netValue={currencyValueCents(custom.netAmount)}
        labelSx={{
          marginLeft: 1,
          color: Colors.fontColorSecondary,
          fontWeight: 400,
        }}
        valueSx={{ color: Colors.fontColorSecondary, fontWeight: 400 }}
        qtySx={{ color: Colors.fontColorSecondary, fontWeight: 400 }}
        refreshing={refreshing}
      />
    ));
  };

  const renderItemDiscounts = () => {
    const itemDiscounts =
      consolidatedShiftSummaryData?.orderSummary?.discounts?.itemDiscounts
        ?.data ?? [];
    return itemDiscounts.map((itemDiscount) => (
      <RowInfo
        label={itemDiscount.name ? `${itemDiscount.name}` : '-'}
        value={currencyValue(itemDiscount.amount)}
        qty={`${itemDiscount.count}`}
        labelSx={{
          marginLeft: 1,
          color: Colors.fontColorSecondary,
          fontWeight: 400,
        }}
        valueSx={{ color: Colors.fontColorSecondary, fontWeight: 400 }}
        qtySx={{ color: Colors.fontColorSecondary, fontWeight: 400 }}
        refreshing={refreshing}
      />
    ));
  };

  const renderOrderDiscounts = () => {
    const orderDiscounts =
      consolidatedShiftSummaryData?.orderSummary?.discounts?.orderDiscounts
        ?.data ?? [];
    return orderDiscounts.map((orderDiscount) => (
      <RowInfo
        label={`${orderDiscount.name}`}
        value={currencyValue(orderDiscount.amount)}
        qty={`${orderDiscount.count}`}
        labelSx={{
          marginLeft: 1,
          color: Colors.fontColorSecondary,
          fontWeight: 400,
        }}
        valueSx={{ color: Colors.fontColorSecondary, fontWeight: 400 }}
        qtySx={{ color: Colors.fontColorSecondary, fontWeight: 400 }}
        refreshing={refreshing}
      />
    ));
  };

  const totalGrossSales =
    consolidatedShiftSummaryData?.orderSummary?.completedOrders?.totalAmount ??
    0;

  const totalDiscounts =
    consolidatedShiftSummaryData?.orderSummary?.discounts?.totalAmount ?? 0;

  const totalDiscountsCount =
    consolidatedShiftSummaryData?.orderSummary?.discounts?.totalCount ?? 0;

  const totalCategorySalesAmount = decimalCalculations(
    consolidatedShiftSummaryData?.orderSummary?.categoryDetails?.totalGross ??
      0,
  ).toNumber();

  const totalServiceCharges =
    consolidatedShiftSummaryData?.orderSummary?.serviceCharges?.totalAmount ??
    0;

  const totalServiceChargeRefunds =
    consolidatedShiftSummaryData?.orderSummary?.serviceCharges
      ?.totalRefundAmount ?? 0;

  const totalGst =
    consolidatedShiftSummaryData?.orderSummary?.gst?.totalAmount ?? 0;

  const totalGstRefunds =
    consolidatedShiftSummaryData?.orderSummary?.gst?.totalRefundAmount ?? 0;

  const totalVoids =
    consolidatedShiftSummaryData?.orderSummary?.voidItems?.totalAmount ?? 0;

  const totalVoidsCount =
    consolidatedShiftSummaryData?.orderSummary?.voidItems?.totalCount ?? 0;

  const totalRefunds =
    consolidatedShiftSummaryData?.paymentsSummary?.refunds?.totalAmount ?? 0;

  const totalRefundAmountWithOutRefundedCharges =
    consolidatedShiftSummaryData?.orderSummary?.categoryDetails?.totalRefunds ??
    0;

  const totalRefundAmount = decimalCalculations(
    consolidatedShiftSummaryData?.orderSummary?.gst?.totalRefundAmount,
  )
    .add(
      consolidatedShiftSummaryData?.orderSummary?.serviceCharges
        ?.totalRefundAmount,
    )
    .add(consolidatedShiftSummaryData?.orderSummary?.custom?.totalRefundAmount)
    .toNumber();

  const totalNetSales = decimalCalculations(totalGrossSales)
    .minus(totalDiscounts)
    .minus(decimalCalculations(totalRefunds).toDecimal().toNumber())
    .add(totalRefundAmount)
    .toNumber();

  const totalFoodCostRefunds = decimalCalculations(totalRefunds)
    .toDecimal()
    .minus(totalRefundAmount)
    .toNumber();

  const netServiceCharge = decimalCalculations(totalServiceCharges)
    .minus(totalServiceChargeRefunds)
    .toNumber();

  const netGst = decimalCalculations(totalGst)
    .minus(totalGstRefunds)
    .toNumber();

  const hideCategoryComponent =
    consolidatedShiftSummaryData?.orderSummary?.categoryDetails
      ?.hideCategoryComponent;

  const isLargeValue =
    totalGrossSales > 999999 ||
    totalDiscounts > 999999 ||
    totalFoodCostRefunds > 999999 ||
    totalNetSales > 999999;

  return (
    <AppPageLayout
      HeaderComponent={ConsolidatedShiftSummaryFilterView}
      TabComponent={ConsolidatedShiftSummaryTabs}
      ContentComponent={() => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            ml: -7,
            mb: 2,
          }}>
          {refreshing && (
            <Backdrop
              open={true}
              sx={{
                position: 'absolute',
                zIndex: 999,
                backgroundColor: 'transparent',
              }}>
              <CircularProgress size="25px" thickness={5} />
            </Backdrop>
          )}
          <Box
            sx={{
              width: {
                xs: '100%',
                lg: '88%',
              },
              ml: 2,
              opacity: refreshing ? 0.5 : 1,
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'end',
                justifyContent: 'flex-end',
                gap: '10px',
                mt: 1,
              }}>
              <AppButton
                title="Expand all"
                sx={{
                  borderRadius: '40px',
                  ml: 1,
                  border: `2px solid ${Colors.greyBorderLogin}`,
                  color: Colors.fontColorPrimary,
                  fontSize: '12px',
                  padding: '3px 15px',
                  height: 30,
                }}
                color="secondary"
                onClick={handleOpenAll}
                startIcon={
                  <UnfoldMoreIcon sx={{ transform: 'rotate(45deg)' }} />
                }
                disabled={Object.values(accordionsExpanded).every(
                  (value) => value,
                )}
                data-testid="expand-all-button"
              />
              <AppButton
                title="Collapse all"
                sx={{
                  borderRadius: '40px',
                  border: `2px solid ${Colors.greyBorderLogin}`,
                  color: Colors.fontColorPrimary,
                  fontSize: '12px',
                  padding: '3px 15px',
                  height: 30,
                }}
                color="secondary"
                onClick={handleCloseAll}
                startIcon={
                  <UnfoldLessIcon
                    sx={{ transform: 'rotate(45deg)', fontSize: '10px' }}
                  />
                }
                disabled={Object.values(accordionsExpanded).every(
                  (value) => !value,
                )}
                data-testid="collapse-all-button"
              />
            </Box>
            <AppDivider
              style={{
                borderColor: Colors.greyBorderLogin,
                borderTopStyle: 'solid',
                mb: '12px',
              }}
            />
            <Box
              sx={{
                display: 'flex',
                gap: '10px',
                mb: 1,
              }}>
              <AppCard
                title="Gross Sales"
                value={formatValue(
                  totalGrossSales,
                  restaurant?.posCurrencySymbol ?? '',
                )}
                refreshing={refreshing}
                valueStyle={{
                  fontSize: isLargeValue ? '18px' : { xs: '18px', lg: '22px' },
                  color: Colors.fontColorSecondary,
                  mt: '5px',
                }}
                dataTestId="gross-sales-card"
              />
              <AppCard
                title="Discounts"
                value={`- ${formatValue(totalDiscounts, restaurant?.posCurrencySymbol ?? '')}`}
                refreshing={refreshing}
                valueStyle={{
                  fontSize: isLargeValue ? '18px' : { xs: '18px', lg: '22px' },
                  color: Colors.failed,
                  mt: '5px',
                }}
                dataTestId="discounts-card"
              />
              <AppCard
                title="Refunds"
                value={`- ${formatValue(totalRefundAmountWithOutRefundedCharges, restaurant?.posCurrencySymbol ?? '')}`}
                refreshing={refreshing}
                valueStyle={{
                  fontSize: isLargeValue ? '18px' : { xs: '18px', lg: '22px' },
                  color: Colors.failed,
                  mt: '5px',
                }}
                dataTestId="refunds-card"
              />
              <AppCard
                title="Net Sales"
                value={formatValue(
                  totalNetSales,
                  restaurant?.posCurrencySymbol ?? '',
                )}
                refreshing={refreshing}
                valueStyle={{
                  fontSize: isLargeValue ? '18px' : { xs: '18px', lg: '22px' },
                  color: Colors.chipSuccessText,
                  mt: '5px',
                }}
                sx={{
                  backgroundColor: Colors.cardSalesBackground,
                  borderColor: Colors.cardSalesBackground,
                }}
                dataTestId="net-sales-card"
              />
            </Box>
            <HashScroll hashInput="#shifts">
              <AppAccordion
                title="Shifts"
                subtitle="All shifts opened during the selected date range"
                rightTopText="Total"
                rightBottomText={`${consolidatedShiftSummaryData?.shiftCount?.opened ?? 0}`}
                expanded={accordionsExpanded.shiftSummary}
                onChange={(_event, isExpanded) => {
                  setAccordionsExpanded({
                    ...accordionsExpanded,
                    shiftSummary: isExpanded,
                  });
                }}>
                <RowInfo
                  label="Opened Shifts"
                  value={consolidatedShiftSummaryData?.shiftCount?.opened ?? 0}
                  refreshing={refreshing}
                />
                <RowInfo
                  label="Closed Shifts"
                  value={consolidatedShiftSummaryData?.shiftCount?.closed ?? 0}
                  refreshing={refreshing}
                />
                <RowInfo
                  label="Ongoing Shifts"
                  value={consolidatedShiftSummaryData?.shiftCount?.ongoing ?? 0}
                  refreshing={refreshing}
                />
              </AppAccordion>
            </HashScroll>

            <HashScroll hashInput="#sales">
              <AppAccordion
                title="Sales"
                subtitle="Summary of all sales"
                rightTopText="Net"
                rightBottomText={`${currencyValueSymbol(totalNetSales)}`}
                expanded={accordionsExpanded.sales}
                onChange={(_event, isExpanded) => {
                  setAccordionsExpanded({
                    ...accordionsExpanded,
                    sales: isExpanded,
                  });
                }}>
                <RowInfo
                  label="Gross Sales"
                  value={currencyValue(totalGrossSales)}
                  refreshing={refreshing}
                />
                <RowInfo
                  label="Discounts"
                  value={`-${currencyValue(totalDiscounts)}`}
                  refreshing={refreshing}
                />
                <RowInfo
                  label={'Refunds (Food Cost)'}
                  value={`-${currencyValue(totalRefundAmountWithOutRefundedCharges)}`}
                  refreshing={refreshing}
                />
                <RowInfo
                  label="Net Sales"
                  value={currencyValue(totalNetSales)}
                  refreshing={refreshing}
                />
                <Box sx={{ mt: 2 }}>
                  <RowInfo
                    label="Total Orders"
                    value={
                      consolidatedShiftSummaryData?.orderSummary
                        ?.completedOrders?.totalCount ?? 0
                    }
                    refreshing={refreshing}
                  />
                  <RowInfo
                    label="Avg. Order Value"
                    value={
                      (consolidatedShiftSummaryData?.orderSummary
                        ?.completedOrders?.totalCount ?? 0) > 0
                        ? decimalCalculations(
                            consolidatedShiftSummaryData?.orderSummary
                              ?.completedOrders?.totalAmount,
                          )
                            .divide(
                              consolidatedShiftSummaryData?.orderSummary
                                ?.completedOrders?.totalCount,
                            )
                            .toMoney()
                        : '0.00'
                    }
                    refreshing={refreshing}
                  />
                </Box>
              </AppAccordion>
            </HashScroll>
            {!hideCategoryComponent && totalCategorySalesAmount >= 0 && (
              <HashScroll hashInput="#category-sales">
                <AppAccordion
                  title="Category Sales"
                  subtitle="Breakdown of sales by categories"
                  rightTopText="Gross"
                  rightBottomText={`${currencyValueSymbol(totalCategorySalesAmount)}`}
                  expanded={accordionsExpanded.categorySales}
                  onChange={(_event, isExpanded) => {
                    setAccordionsExpanded({
                      ...accordionsExpanded,
                      categorySales: isExpanded,
                    });
                  }}>
                  {/* Check total GST have more than 0 amount */}
                  {(consolidatedShiftSummaryData?.orderSummary?.categoryDetails
                    .totalGST ?? 0) <= 0 ? (
                    <CategorySalesRowInfo
                      grossAmount="Gross"
                      discountAmount="Discounts"
                      refundsAmount="Refunds"
                      categoryNameSx={{ fontWeight: 600 }}
                      discountAmountSx={{ fontWeight: 600 }}
                      grossAmountSx={{ fontWeight: 600 }}
                      netGstAmountSx={{ fontWeight: 600 }}
                      refundedGstAmountSx={{ fontWeight: 600 }}
                      refundsAmountSx={{ fontWeight: 600 }}
                      refreshing={refreshing}
                    />
                  ) : (
                    <CategorySalesRowInfo
                      grossAmount="Gross"
                      discountAmount="Discounts"
                      netGstAmount="Net GST"
                      refundedGstAmount="Refunded GST"
                      refundsAmount="Refunds"
                      categoryNameSx={{ fontWeight: 600 }}
                      discountAmountSx={{ fontWeight: 600 }}
                      grossAmountSx={{ fontWeight: 600 }}
                      netGstAmountSx={{ fontWeight: 600 }}
                      refundedGstAmountSx={{ fontWeight: 600 }}
                      refundsAmountSx={{ fontWeight: 600 }}
                      refreshing={refreshing}
                    />
                  )}

                  <AppDivider
                    style={{
                      borderColor: Colors.greyBorderLogin,
                      borderTopStyle: 'dashed',
                      mb: '12px',
                    }}
                  />
                  <Box sx={{ mb: 2 }}>
                    {/* Check total GST have more than 0 amount */}
                    {(consolidatedShiftSummaryData?.orderSummary
                      ?.categoryDetails.totalGST ?? 0) <= 0 ? (
                      <CategorySalesRowInfo
                        categoryName="Total"
                        itemSalesCount={
                          consolidatedShiftSummaryData?.orderSummary
                            ?.categoryDetails?.totalCount ?? 0
                        }
                        grossAmount={decimalCalculations(
                          consolidatedShiftSummaryData?.orderSummary
                            ?.categoryDetails?.totalGross ?? 0,
                        ).toMoney()}
                        discountAmount={decimalCalculations(
                          consolidatedShiftSummaryData?.orderSummary
                            ?.categoryDetails?.totalDiscounts ?? 0,
                        ).toMoney()}
                        refundsAmount={decimalCalculations(
                          consolidatedShiftSummaryData?.orderSummary
                            ?.categoryDetails?.totalRefunds ?? 0,
                        ).toMoney()}
                        refreshing={refreshing}
                        categoryNameSx={{ fontWeight: 500 }}
                        itemSalesCountSx={{ fontWeight: 500 }}
                        discountAmountSx={{ fontWeight: 500 }}
                        grossAmountSx={{ fontWeight: 500 }}
                        refundsAmountSx={{ fontWeight: 500 }}
                      />
                    ) : (
                      <CategorySalesRowInfo
                        categoryName="Total"
                        itemSalesCount={
                          consolidatedShiftSummaryData?.orderSummary
                            ?.categoryDetails?.totalCount ?? 0
                        }
                        grossAmount={decimalCalculations(
                          consolidatedShiftSummaryData?.orderSummary
                            ?.categoryDetails?.totalGross ?? 0,
                        ).toMoney()}
                        discountAmount={decimalCalculations(
                          consolidatedShiftSummaryData?.orderSummary
                            ?.categoryDetails?.totalDiscounts ?? 0,
                        ).toMoney()}
                        netGstAmount={currencyValue(netGst)}
                        refundedGstAmount={currencyValue(totalGstRefunds)}
                        refundsAmount={decimalCalculations(
                          consolidatedShiftSummaryData?.orderSummary
                            ?.categoryDetails?.totalRefunds ?? 0,
                        ).toMoney()}
                        refreshing={refreshing}
                        categoryNameSx={{ fontWeight: 500 }}
                        itemSalesCountSx={{ fontWeight: 500 }}
                        discountAmountSx={{ fontWeight: 500 }}
                        grossAmountSx={{ fontWeight: 500 }}
                        netGstAmountSx={{ fontWeight: 500 }}
                        refundedGstAmountSx={{ fontWeight: 500 }}
                        refundsAmountSx={{ fontWeight: 500 }}
                      />
                    )}
                  </Box>

                  {categorySalesSummary()}

                  {consolidatedShiftSummaryData?.orderSummary?.categoryDetails
                    ?.custom?.grossTotal !== undefined &&
                    consolidatedShiftSummaryData?.orderSummary?.categoryDetails
                      ?.custom?.grossTotal > 0 &&
                    ((consolidatedShiftSummaryData?.orderSummary
                      ?.categoryDetails?.totalGST ?? 0) <= 0 ? (
                      <CategorySalesRowInfo
                        categoryName={
                          consolidatedShiftSummaryData?.orderSummary
                            ?.categoryDetails?.custom?.categoryName
                        }
                        itemSalesCount={
                          consolidatedShiftSummaryData?.orderSummary
                            ?.categoryDetails?.custom?.noOfSales
                        }
                        grossAmount={decimalCalculations(
                          consolidatedShiftSummaryData?.orderSummary
                            ?.categoryDetails?.custom?.grossTotal,
                        ).toMoney()}
                        discountAmount={decimalCalculations(
                          consolidatedShiftSummaryData?.orderSummary
                            ?.categoryDetails?.custom?.discounts,
                        ).toMoney()}
                        refundsAmount={decimalCalculations(
                          consolidatedShiftSummaryData?.orderSummary
                            ?.categoryDetails?.custom?.refunds,
                        ).toMoney()}
                        refreshing={refreshing}
                      />
                    ) : (
                      <CategorySalesRowInfo
                        categoryName={
                          consolidatedShiftSummaryData?.orderSummary
                            ?.categoryDetails?.custom?.categoryName
                        }
                        itemSalesCount={
                          consolidatedShiftSummaryData?.orderSummary
                            ?.categoryDetails?.custom?.noOfSales
                        }
                        grossAmount={decimalCalculations(
                          consolidatedShiftSummaryData?.orderSummary
                            ?.categoryDetails?.custom?.grossTotal,
                        ).toMoney()}
                        discountAmount={decimalCalculations(
                          consolidatedShiftSummaryData?.orderSummary
                            ?.categoryDetails?.custom?.discounts,
                        ).toMoney()}
                        netGstAmount={decimalCalculations(
                          consolidatedShiftSummaryData?.orderSummary
                            ?.categoryDetails?.custom?.gst,
                        ).toMoney()}
                        refundedGstAmount={decimalCalculations(
                          consolidatedShiftSummaryData?.orderSummary
                            ?.categoryDetails?.custom?.refundedGST,
                        ).toMoney()}
                        refundsAmount={decimalCalculations(
                          consolidatedShiftSummaryData?.orderSummary
                            ?.categoryDetails?.custom?.refunds,
                        ).toMoney()}
                        refreshing={refreshing}
                      />
                    ))}
                </AppAccordion>
              </HashScroll>
            )}
            {totalServiceCharges >= 0 && (
              <HashScroll hashInput="#service-charge">
                <AppAccordion
                  title="Service Charge"
                  subtitle="Total service charge"
                  rightTopText="Net"
                  rightBottomText={`${currencyValueSymbol(netServiceCharge)}`}
                  expanded={accordionsExpanded.serviceCharge}
                  onChange={(_event, isExpanded) => {
                    setAccordionsExpanded({
                      ...accordionsExpanded,
                      serviceCharge: isExpanded,
                    });
                  }}>
                  <RowInfo
                    label="Total"
                    value={currencyValue(totalServiceCharges)}
                    refreshing={refreshing}
                    sum
                  />
                  <RowInfo
                    label="Refunded"
                    value={`-${currencyValue(totalServiceChargeRefunds)}`}
                    refreshing={refreshing}
                    sum
                  />
                  <RowInfo
                    label="Net"
                    value={currencyValue(netServiceCharge)}
                    refreshing={refreshing}
                    sum
                  />
                </AppAccordion>
              </HashScroll>
            )}
            {totalGst >= 0 && (
              <HashScroll hashInput="#taxes">
                <AppAccordion
                  title="Taxes"
                  subtitle="Total GST"
                  rightTopText="Net GST"
                  rightBottomText={currencyValueSymbol(netGst)}
                  expanded={accordionsExpanded.taxes}
                  onChange={(_event, isExpanded) => {
                    setAccordionsExpanded({
                      ...accordionsExpanded,
                      taxes: isExpanded,
                    });
                  }}>
                  <RowInfo
                    label="Total GST"
                    value={currencyValue(totalGst)}
                    refreshing={refreshing}
                    sum
                  />
                  <RowInfo
                    label="Refunded GST"
                    value={`-${currencyValue(totalGstRefunds)}`}
                    refreshing={refreshing}
                    sum
                  />
                  <RowInfo
                    label="Net GST"
                    value={currencyValue(netGst)}
                    refreshing={refreshing}
                    sum
                  />
                </AppAccordion>
              </HashScroll>
            )}
            {totalDiscountsCount >= 0 && (
              <HashScroll hashInput="#discounts">
                <AppAccordion
                  title="Discounts"
                  subtitle="Summary of all discounts"
                  rightTopText="Total"
                  rightBottomText={currencyValueSymbol(totalDiscounts)}
                  expanded={accordionsExpanded.discounts}
                  onChange={(_event, isExpanded) => {
                    setAccordionsExpanded({
                      ...accordionsExpanded,
                      discounts: isExpanded,
                    });
                  }}>
                  <RowInfo
                    label="Total"
                    value={`${currencyValue(totalDiscounts)}`}
                    qty={String(totalDiscountsCount)}
                    labelSx={{ fontWeight: 500 }}
                    refreshing={refreshing}
                    valueSx={{ fontWeight: 600 }}
                    qtySx={{ fontWeight: 500 }}
                  />
                  <Box sx={{ mt: 2 }}>
                    <RowInfo
                      label="Item Discounts"
                      value={`${currencyValue(
                        consolidatedShiftSummaryData?.orderSummary?.discounts
                          ?.itemDiscounts?.totalAmount ?? 0,
                      )}`}
                      qty={`${consolidatedShiftSummaryData?.orderSummary?.discounts?.itemDiscounts?.totalCount ?? 0}`}
                      refreshing={refreshing}
                      labelSx={{ fontWeight: 400 }}
                      valueSx={{ fontWeight: 400 }}
                      qtySx={{ fontWeight: 400 }}
                    />
                  </Box>
                  {renderItemDiscounts()}
                  <Box sx={{ mt: 2 }}>
                    <RowInfo
                      label="Order Discounts"
                      value={`${currencyValue(
                        consolidatedShiftSummaryData?.orderSummary?.discounts
                          ?.orderDiscounts?.totalAmount ?? 0,
                      )}`}
                      qty={`${consolidatedShiftSummaryData?.orderSummary?.discounts?.orderDiscounts?.totalCount ?? 0}`}
                      refreshing={refreshing}
                      labelSx={{ fontWeight: 400 }}
                      valueSx={{ fontWeight: 400 }}
                      qtySx={{ fontWeight: 400 }}
                    />
                    {renderOrderDiscounts()}
                  </Box>
                </AppAccordion>
              </HashScroll>
            )}
            {totalVoidsCount >= 0 && (
              <HashScroll hashInput="#voids">
                <AppAccordion
                  title="Voids"
                  subtitle="Summary of item voids"
                  rightTopText="Total"
                  rightBottomText={currencyValueSymbol(totalVoids)}
                  expanded={accordionsExpanded.voids}
                  onChange={(_event, isExpanded) => {
                    setAccordionsExpanded({
                      ...accordionsExpanded,
                      voids: isExpanded,
                    });
                  }}>
                  <RowInfo
                    label="Item Voids"
                    value={currencyValue(totalVoids)}
                    qty={String(totalVoidsCount)}
                    refreshing={refreshing}
                  />
                </AppAccordion>
              </HashScroll>
            )}
            <HashScroll hashInput="#cash-summary">
              <AppAccordion
                title="Cash Summary"
                subtitle="Summary of cash inflows and outflows"
                expanded={accordionsExpanded.cashSummary}
                onChange={(_event, isExpanded) => {
                  setAccordionsExpanded({
                    ...accordionsExpanded,
                    cashSummary: isExpanded,
                  });
                }}>
                <RowInfo
                  label="Opening Balance"
                  value={currencyValueCents(
                    consolidatedShiftSummaryData?.paymentsSummary?.cashDrawer
                      ?.openingBalance ?? 0,
                  )}
                  refreshing={refreshing}
                />
                <RowInfo
                  label="Cash In"
                  value={`${currencyValueCents(
                    consolidatedShiftSummaryData?.paymentsSummary?.cashDrawer
                      ?.cashIn ?? 0,
                  )}`}
                  refreshing={refreshing}
                />
                <RowInfo
                  label="Cash Transaction"
                  value={`${currencyValueCents(
                    consolidatedShiftSummaryData?.paymentsSummary?.cashDrawer
                      ?.cashTransactions ?? 0,
                  )}`}
                  refreshing={refreshing}
                />
                <RowInfo
                  label="Cash Out"
                  value={`-${currencyValueCents(
                    consolidatedShiftSummaryData?.paymentsSummary?.cashDrawer
                      ?.cashOut ?? 0,
                  )}`}
                  refreshing={refreshing}
                />
                <RowInfo
                  label="Cash Refunds"
                  value={`-${currencyValueCents(
                    consolidatedShiftSummaryData?.paymentsSummary?.cashDrawer
                      ?.cashRefunds ?? 0,
                  )}`}
                  refreshing={refreshing}
                />
                <RowInfo
                  label="Expected Cash Amount"
                  value={currencyValueCents(
                    consolidatedShiftSummaryData?.paymentsSummary?.cashDrawer
                      ?.expectedBalance ?? 0,
                  )}
                  sum
                  refreshing={refreshing}
                />
                {(consolidatedShiftSummaryData?.shiftCount?.closed ?? 0) >
                  0 && (
                  <>
                    <RowInfo
                      label="Actual Cash Amount"
                      value={currencyValueCents(
                        consolidatedShiftSummaryData?.paymentsSummary
                          ?.cashDrawer?.closingBalance ?? 0,
                      )}
                      sum
                      refreshing={refreshing}
                    />
                    <RowInfo
                      label="Difference"
                      value={decimalCalculations(
                        consolidatedShiftSummaryData?.paymentsSummary
                          ?.cashDrawer?.closingBalance,
                      )
                        .minus(
                          consolidatedShiftSummaryData?.paymentsSummary
                            ?.cashDrawer?.expectedBalance,
                        )
                        .toDecimal()
                        .toMoney()}
                      sum
                      refreshing={refreshing}
                    />
                  </>
                )}
              </AppAccordion>
            </HashScroll>
            {totalVoidsCount >= 0 && (
              <HashScroll hashInput="#payments">
                <AppAccordion
                  title="Payments"
                  subtitle="Summary of all payment types and amounts"
                  rightTopText="Net Total"
                  rightBottomText={currencyValueSymbolCents(
                    consolidatedShiftSummaryData?.paymentsSummary?.netPayments
                      ?.totalNetAmount ?? 0,
                  )}
                  expanded={accordionsExpanded.payments}
                  onChange={(_event, isExpanded) => {
                    setAccordionsExpanded({
                      ...accordionsExpanded,
                      payments: isExpanded,
                    });
                  }}>
                  <PaymentsRowInfo
                    label=""
                    processedValue="Processed"
                    processedQty=""
                    refundsValue="Refunds"
                    refundsQty=""
                    netValue="Net"
                    labelSx={{ fontWeight: 500 }}
                    valueSx={{ marginLeft: -5 }}
                    refundSx={{
                      marginLeft: 5,
                      display: 'flex',
                      justifyContent: 'right',
                      fontWeight: 500,
                    }}
                    refreshing={refreshing}
                  />
                  <AppDivider
                    style={{
                      borderColor: Colors.greyBorderLogin,
                      borderTopStyle: 'dashed',
                      mb: '12px',
                    }}
                  />
                  <PaymentsRowInfo
                    label="Total"
                    processedValue={currencyValueCents(
                      consolidatedShiftSummaryData?.paymentsSummary?.netPayments
                        ?.totalProcessedAmount ?? 0,
                    )}
                    processedQty={`${consolidatedShiftSummaryData?.paymentsSummary?.netPayments?.totalProcessedCount ?? 0}`}
                    refundsValue={`-${currencyValueCents(
                      consolidatedShiftSummaryData?.paymentsSummary?.netPayments
                        ?.totalRefundedAmount ?? 0,
                    )}`}
                    refundsQty={String(
                      consolidatedShiftSummaryData?.paymentsSummary?.netPayments
                        ?.totalRefundedCount ?? 0,
                    )}
                    netValue={currencyValueCents(
                      consolidatedShiftSummaryData?.paymentsSummary?.netPayments
                        ?.totalNetAmount ?? 0,
                    )}
                    labelSx={{ fontWeight: 500 }}
                    refreshing={refreshing}
                  />
                  <Box sx={{ mt: 2 }}>
                    <PaymentsRowInfo
                      label="Card"
                      processedValue={currencyValueCents(
                        consolidatedShiftSummaryData?.paymentsSummary
                          ?.netPayments?.cards?.totalProcessedAmount ?? 0,
                      )}
                      processedQty={`${consolidatedShiftSummaryData?.paymentsSummary?.netPayments?.cards?.totalProcessedCount ?? 0}`}
                      refundsValue={`-${currencyValueCents(
                        consolidatedShiftSummaryData?.paymentsSummary
                          ?.netPayments?.cards?.totalRefundedAmount ?? 0,
                      )}`}
                      refundsQty={`${consolidatedShiftSummaryData?.paymentsSummary?.netPayments?.cards?.totalRefundedCount ?? 0}`}
                      netValue={currencyValueCents(
                        consolidatedShiftSummaryData?.paymentsSummary
                          ?.netPayments?.cards?.totalNetAmount ?? 0,
                      )}
                      refreshing={refreshing}
                      labelSx={{ fontWeight: 400 }}
                      valueSx={{ fontWeight: 400 }}
                      qtySx={{ fontWeight: 400 }}
                    />
                    {netPaymentCardSummary()}
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <PaymentsRowInfo
                      label="Cash"
                      processedValue={currencyValueCents(
                        consolidatedShiftSummaryData?.paymentsSummary
                          ?.netPayments?.cash?.processedAmount ?? 0,
                      )}
                      processedQty={`${consolidatedShiftSummaryData?.paymentsSummary?.netPayments?.cash?.processedCount ?? 0}`}
                      refundsValue={`-${currencyValueCents(
                        consolidatedShiftSummaryData?.paymentsSummary
                          ?.netPayments?.cash?.refundedAmount ?? 0,
                      )}`}
                      refundsQty={`${consolidatedShiftSummaryData?.paymentsSummary?.netPayments?.cash?.refundedCount ?? 0}`}
                      netValue={currencyValueCents(
                        consolidatedShiftSummaryData?.paymentsSummary
                          ?.netPayments?.cash?.netAmount ?? 0,
                      )}
                      refreshing={refreshing}
                      labelSx={{ fontWeight: 400 }}
                      valueSx={{ fontWeight: 400 }}
                      qtySx={{ fontWeight: 400 }}
                    />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <PaymentsRowInfo
                      label="Others"
                      processedValue={currencyValueCents(
                        consolidatedShiftSummaryData?.paymentsSummary
                          ?.netPayments?.custom?.totalProcessedAmount ?? 0,
                      )}
                      processedQty={`${consolidatedShiftSummaryData?.paymentsSummary?.netPayments?.custom?.totalProcessedCount ?? 0}`}
                      refundsValue={`-${currencyValueCents(
                        consolidatedShiftSummaryData?.paymentsSummary
                          ?.netPayments?.custom?.totalRefundedAmount ?? 0,
                      )}`}
                      refundsQty={`${consolidatedShiftSummaryData?.paymentsSummary?.netPayments?.custom?.totalRefundedCount ?? 0}`}
                      netValue={currencyValueCents(
                        consolidatedShiftSummaryData?.paymentsSummary
                          ?.netPayments?.custom?.totalNetAmount ?? 0,
                      )}
                      refreshing={refreshing}
                      labelSx={{ fontWeight: 400 }}
                      valueSx={{ fontWeight: 400 }}
                      qtySx={{ fontWeight: 400 }}
                    />
                    {netPaymentCustomSummary()}
                  </Box>
                </AppAccordion>
              </HashScroll>
            )}
          </Box>
        </Box>
      )}
    />
  );
}

export default ConsolidatedShiftSummaryPage;
