import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { SxProps } from '@mui/material/styles';
import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';

import AppButton from '../../../components/AppButton';
import { SNACKBARTYPE, useSnackbar } from '../../../components/AppSnackbar';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { selectAuth } from '../../../redux/selectors/authSelectors';
import { Colors } from '../../../theme/colors';
import { decimalCalculations } from '../../../utils';
import {
  ExportReportType,
  IConsolidatedShiftSummaryResponseDTO,
  ISingleShiftSummaryResponseDTO,
} from '../types/shift-summary.types';

interface ShiftSummaryExportButtonProps<T> {
  data: T | null;
  reportStartDate?: string;
  reportEndDate?: string;
  fileName: string;
  disabled?: boolean;
  buttonSx?: SxProps;
  iconSx?: SxProps;
  report: ExportReportType;
}

function ShiftSummaryExportButton<
  T extends
    | ISingleShiftSummaryResponseDTO
    | IConsolidatedShiftSummaryResponseDTO,
>({
  data,
  reportStartDate,
  reportEndDate,
  fileName,
  disabled,
  buttonSx,
  iconSx,
  report,
}: ShiftSummaryExportButtonProps<T>) {
  const { openSnackbar } = useSnackbar();
  const { restaurant } = useAppSelector(selectAuth);

  const timeZone = restaurant?.timeZoneValue ? restaurant?.timeZoneValue : '';

  const formattedDateTime = useCallback(
    (value: Date | undefined, returnType: string) => {
      const dateTime = dayjs(value).utcOffset(timeZone);

      if (!dateTime.isValid()) {
        return '';
      }

      if (returnType === 'date') {
        return dateTime.format('YYYY/MM/DD');
      } else if (returnType === 'time') {
        return dateTime.format('hh:mm A');
      }
    },
    [timeZone],
  );

  const isShiftWithin30Days = useMemo(() => {
    if (report !== ExportReportType.singleShift) return true;
    if (!(data as ISingleShiftSummaryResponseDTO)?.shift?.createdAt)
      return false;

    const shiftDate = dayjs(
      (data as ISingleShiftSummaryResponseDTO).shift.createdAt,
    );
    const thirtyDaysAgo = dayjs().subtract(30, 'days');
    return shiftDate.isAfter(thirtyDaysAgo);
  }, [data, report]);

  const handleExport = async () => {
    if (!data) {
      openSnackbar(
        'We couldn’t find any data to export based on your selections!',
        SNACKBARTYPE.ERROR,
      );
      return;
    }

    const formattedData = formatExportData({
      data,
      reportStartDate,
      reportEndDate,
      fileName,
      report,
    });

    const csvContent = `data:text/csv;charset=utf-8,${formattedData.join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute(
      'download',
      `${fileName}${
        report === ExportReportType.singleShift &&
        (data as ISingleShiftSummaryResponseDTO)?.shift?.shiftNumber
          ? `_${(data as ISingleShiftSummaryResponseDTO).shift.shiftNumber}`
          : ''
      }_${dayjs().utcOffset(timeZone).format('DDMMYY_hhmm')}.csv`,
    );

    document.body.appendChild(link);
    link.click();
  };

  const totalGrossSales = data?.orderSummary?.completedOrders?.totalAmount ?? 0;

  const totalDiscounts = data?.orderSummary?.discounts?.totalAmount ?? 0;

  const totalDiscountsCount = data?.orderSummary?.discounts?.totalCount ?? 0;

  const totalServiceCharges =
    data?.orderSummary?.serviceCharges?.totalAmount ?? 0;

  const totalServiceChargeRefunds =
    data?.orderSummary?.serviceCharges?.totalRefundAmount ?? 0;

  const totalGst = data?.orderSummary?.gst?.totalAmount ?? 0;

  const totalGstRefunds = data?.orderSummary?.gst?.totalRefundAmount ?? 0;

  const totalVoids = data?.orderSummary?.voidItems?.totalAmount ?? 0;

  const totalVoidsCount = data?.orderSummary?.voidItems?.totalCount ?? 0;

  const totalRefunds = data?.paymentsSummary?.refunds?.totalAmount ?? 0;

  const totalRefundAmountWithOutRefundedCharges =
    data?.orderSummary?.categoryDetails?.totalRefunds ?? 0;

  const totalRefundAmount = decimalCalculations(
    data?.orderSummary?.gst?.totalRefundAmount,
  )
    .add(data?.orderSummary?.serviceCharges?.totalRefundAmount)
    .add(data?.orderSummary?.custom?.totalRefundAmount)
    .toNumber();

  const totalNetSales = decimalCalculations(totalGrossSales)
    .minus(totalDiscounts)
    .minus(decimalCalculations(totalRefunds).toDecimal().toNumber())
    .add(totalRefundAmount)
    .toNumber();

  const netServiceCharge = decimalCalculations(totalServiceCharges)
    .minus(totalServiceChargeRefunds)
    .toNumber();

  const netGst = decimalCalculations(totalGst)
    .minus(totalGstRefunds)
    .toNumber();

  const closedBy = (data as ISingleShiftSummaryResponseDTO)?.shift
    ?.closedByName;

  const formatExportData = ({
    data,
    reportStartDate,
    reportEndDate,
  }: ShiftSummaryExportButtonProps<T>) => {
    if (!data) return [];
    const rows = [];

    const formattedStartDate = reportStartDate
      ? dayjs(reportStartDate).format('DD/MM/YYYY')
      : '';
    const formattedEndDate = reportEndDate
      ? dayjs(reportEndDate).format('DD/MM/YYYY')
      : '';

    let shiftClosedValue = '-';
    if (
      data &&
      (data as ISingleShiftSummaryResponseDTO)?.shift?.status !== 'OPEN'
    ) {
      shiftClosedValue = `by ${
        (data as ISingleShiftSummaryResponseDTO)?.shift?.closedByName
      } on ${formattedDateTime(
        (data as ISingleShiftSummaryResponseDTO)?.shift?.closedAt,
        'date',
      )} at ${formattedDateTime((data as ISingleShiftSummaryResponseDTO)?.shift?.closedAt, 'time')}`;
    }

    let shiftOpenedValue = '-';
    if (data) {
      shiftOpenedValue = `by ${
        (data as ISingleShiftSummaryResponseDTO)?.shift?.openedByName
      } on ${formattedDateTime(
        (data as ISingleShiftSummaryResponseDTO)?.shift?.createdAt,
        'date',
      )} at ${formattedDateTime((data as ISingleShiftSummaryResponseDTO)?.shift?.createdAt, 'time')}`;
    }

    const showDifference =
      (data as ISingleShiftSummaryResponseDTO)?.shift?.closedAt ||
      (data as IConsolidatedShiftSummaryResponseDTO)?.shiftCount?.closed > 0;

    // Summary
    if (reportStartDate && reportEndDate) {
      rows.push(
        ['From', '', `\t${formattedStartDate}`],
        ['To', '', `\t${formattedEndDate}`],
      );
    }

    if (report === ExportReportType.consolidatedShift) {
      rows.push(['', '']);
      rows.push(['Shifts', '']);
      rows.push([
        'Opened Shifts',
        '',
        (data as IConsolidatedShiftSummaryResponseDTO)?.shiftCount?.opened ?? 0,
      ]);
    }

    if (report === ExportReportType.consolidatedShift) {
      rows.push([
        'Closed Shifts',
        '',
        (data as IConsolidatedShiftSummaryResponseDTO)?.shiftCount?.closed ?? 0,
      ]);
    }

    if (report === ExportReportType.consolidatedShift) {
      rows.push([
        'Ongoing Shifts',
        '',
        (data as IConsolidatedShiftSummaryResponseDTO)?.shiftCount?.ongoing ??
          0,
      ]);
    }

    if (report === ExportReportType.singleShift) {
      rows.push(['Shift Opened', '', shiftOpenedValue]);
    }

    if (closedBy) {
      rows.push(['Shift Closed', '', shiftClosedValue]);
    }

    // Sales
    rows.push(['', '']);
    rows.push(['Sales', '']);
    rows.push([
      'Gross Sales',
      '',
      decimalCalculations(totalGrossSales).toMoney('', true),
    ]);
    rows.push([
      'Discounts',
      '',
      `-${decimalCalculations(totalDiscounts).toMoney('', true)}`,
    ]);
    rows.push([
      'Refunds (Food Cost)',
      '',
      `-${decimalCalculations(totalRefundAmountWithOutRefundedCharges).toMoney('', true)}`,
    ]);
    rows.push([
      'Net Sales',
      '',
      decimalCalculations(totalNetSales).toMoney('', true),
    ]);
    rows.push([
      'Total Orders',
      '',
      data?.orderSummary?.completedOrders?.totalCount ?? 0,
    ]);
    rows.push([
      'Avg. Order Value',
      '',
      (data?.orderSummary?.completedOrders?.totalCount ?? 0) > 0
        ? decimalCalculations(data?.orderSummary?.completedOrders?.totalAmount)
            .divide(data?.orderSummary?.completedOrders?.totalCount)
            .toMoney('', true)
        : '0.00',
    ]);

    // Category Sales - Only include if the shift is open within 30 days
    if (
      isShiftWithin30Days &&
      !(data as IConsolidatedShiftSummaryResponseDTO).orderSummary
        .categoryDetails.hideCategoryComponent
    ) {
      rows.push(['', '']);
      rows.push(['Category Sales', '']);
      if ((data?.orderSummary?.categoryDetails?.totalGST ?? 0) > 0) {
        rows.push([
          '',
          '',
          'Gross',
          'Discounts',
          'Net GST',
          'Refunded GST',
          'Refunds',
        ]);
      } else {
        rows.push(['', '', 'Gross', 'Discounts', 'Refunds']);
      }

      if ((data?.orderSummary?.categoryDetails?.totalGST ?? 0) > 0) {
        rows.push([
          'Total',
          data?.orderSummary?.categoryDetails?.totalCount ?? 0,
          decimalCalculations(
            data?.orderSummary?.categoryDetails?.totalGross ?? 0,
          ).toMoney('', true),

          decimalCalculations(
            data?.orderSummary?.categoryDetails?.totalDiscounts ?? 0,
          ).toMoney('', true),
          decimalCalculations(netGst).toMoney('', true),

          decimalCalculations(totalGstRefunds).toMoney('', true),

          decimalCalculations(
            data?.orderSummary?.categoryDetails?.totalRefunds ?? 0,
          ).toMoney('', true),
        ]);
      } else {
        rows.push([
          'Total',
          data?.orderSummary?.categoryDetails?.totalCount ?? 0,
          decimalCalculations(
            data?.orderSummary?.categoryDetails?.totalGross ?? 0,
          ).toMoney('', true),
          decimalCalculations(
            data?.orderSummary?.categoryDetails?.totalDiscounts ?? 0,
          ).toMoney('', true),
          decimalCalculations(
            data?.orderSummary?.categoryDetails?.totalRefunds ?? 0,
          ).toMoney('', true),
        ]);
      }

      if ((data?.orderSummary?.categoryDetails?.totalGST ?? 0) > 0) {
        data?.orderSummary?.categoryDetails?.data?.forEach((category) => {
          rows.push([
            `${category.categoryName}`,
            category.noOfSales,
            decimalCalculations(category.grossTotal ?? 0).toMoney('', true),
            decimalCalculations(category.discounts ?? 0).toMoney('', true),
            decimalCalculations(category.gst ?? 0).toMoney('', true),
            decimalCalculations(category.refundedGST ?? 0).toMoney('', true),
            decimalCalculations(category.refunds ?? 0).toMoney('', true),
          ]);
        });
      } else {
        data?.orderSummary?.categoryDetails?.data?.forEach((category) => {
          rows.push([
            `${category.categoryName}`,
            category.noOfSales,
            decimalCalculations(category.grossTotal ?? 0).toMoney('', true),
            decimalCalculations(category.discounts ?? 0).toMoney('', true),
            decimalCalculations(category.refunds ?? 0).toMoney('', true),
          ]);
        });
      }

      if ((data?.orderSummary?.categoryDetails?.totalGST ?? 0) > 0) {
        if (
          data?.orderSummary?.categoryDetails?.custom?.grossTotal !==
            undefined &&
          data?.orderSummary?.categoryDetails?.custom?.grossTotal > 0
        ) {
          rows.push([
            `${data.orderSummary.categoryDetails.custom.categoryName}`,
            data?.orderSummary?.categoryDetails?.custom.noOfSales ?? 0,
            decimalCalculations(
              data?.orderSummary?.categoryDetails?.custom.grossTotal ?? 0,
            ).toMoney('', true),

            decimalCalculations(
              data?.orderSummary?.categoryDetails?.custom.discounts ?? 0,
            ).toMoney('', true),

            decimalCalculations(
              data?.orderSummary?.categoryDetails?.custom.gst ?? 0,
            ).toMoney('', true),

            decimalCalculations(
              data?.orderSummary?.categoryDetails?.custom.refundedGST ?? 0,
            ).toMoney('', true),

            decimalCalculations(
              data?.orderSummary?.categoryDetails?.custom.refunds ?? 0,
            ).toMoney('', true),
          ]);
        }
      } else {
        if (
          data?.orderSummary?.categoryDetails?.custom?.grossTotal !==
            undefined &&
          data?.orderSummary?.categoryDetails?.custom?.grossTotal > 0
        ) {
          rows.push([
            `${data.orderSummary.categoryDetails.custom.categoryName}`,
            data?.orderSummary?.categoryDetails?.custom.noOfSales ?? 0,
            decimalCalculations(
              data?.orderSummary?.categoryDetails?.custom.grossTotal ?? 0,
            ).toMoney('', true),

            decimalCalculations(
              data?.orderSummary?.categoryDetails?.custom.discounts ?? 0,
            ).toMoney('', true),

            decimalCalculations(
              data?.orderSummary?.categoryDetails?.custom.refunds ?? 0,
            ).toMoney('', true),
          ]);
        }
      }
    }

    // Service Charge
    rows.push(['', '']);
    rows.push(['Service Charge', '']);
    rows.push([
      'Total',
      '',
      decimalCalculations(totalServiceCharges).toMoney('', true),
    ]);
    rows.push([
      'Refunded',
      '',
      `-${decimalCalculations(totalServiceChargeRefunds).toMoney('', true)}`,
    ]);
    rows.push([
      'Net',
      '',
      decimalCalculations(netServiceCharge).toMoney('', true),
    ]);

    // GST
    rows.push(['', '']);
    rows.push(['Taxes', '']);
    rows.push([
      'Total GST',
      '',
      decimalCalculations(totalGst).toMoney('', true),
    ]);
    rows.push([
      'Refunded GST',
      '',
      `-${decimalCalculations(totalGstRefunds).toMoney('', true)}`,
    ]);

    rows.push(['Net GST', '', decimalCalculations(netGst).toMoney('', true)]);

    // Discounts
    rows.push(['', '']);
    rows.push(['Discounts', '']);
    rows.push([
      'Total',
      totalDiscountsCount,
      decimalCalculations(totalDiscounts).toMoney('', true),
    ]);
    rows.push([
      'Item Discounts',
      data?.orderSummary?.discounts?.itemDiscounts?.totalCount ?? 0,
      decimalCalculations(
        data?.orderSummary?.discounts?.itemDiscounts?.totalAmount ?? 0,
      ).toMoney('', true),
    ]);
    data?.orderSummary?.discounts?.itemDiscounts?.data?.forEach((item) => {
      rows.push([
        `  ${item.name}`,
        item.count ?? 0,
        decimalCalculations(item.amount).toMoney('', true),
      ]);
    });
    rows.push([
      'Order Discounts',
      data?.orderSummary?.discounts?.orderDiscounts?.totalCount ?? 0,
      decimalCalculations(
        data?.orderSummary?.discounts?.orderDiscounts?.totalAmount ?? 0,
      ).toMoney('', true),
    ]);
    data?.orderSummary?.discounts?.orderDiscounts?.data?.forEach((order) => {
      rows.push([
        `  ${order.name}`,
        order.count ?? 0,
        decimalCalculations(order.amount).toMoney('', true),
      ]);
    });

    // Voids
    rows.push(['', '']);
    rows.push(['Voids', '']);
    rows.push([
      'Item Voids',
      totalVoidsCount,
      decimalCalculations(totalVoids).toMoney('', true),
    ]);

    // Cash Summary
    rows.push(['', '']);
    rows.push(['Cash Summary', '']);
    rows.push([
      'Opening Balance',
      '',
      decimalCalculations(
        data?.paymentsSummary?.cashDrawer?.openingBalance ?? 0,
      )
        .toDecimal()
        .toMoney('', true),
    ]);
    rows.push([
      'Cash In',
      '',
      decimalCalculations(data?.paymentsSummary?.cashDrawer?.cashIn ?? 0)
        .toDecimal()
        .toMoney('', true),
    ]);
    rows.push([
      'Cash Transaction',
      '',
      decimalCalculations(
        data?.paymentsSummary?.cashDrawer?.cashTransactions ?? 0,
      )
        .toDecimal()
        .toMoney('', true),
    ]);
    rows.push([
      'Cash Out',
      '',
      `-${decimalCalculations(data?.paymentsSummary?.cashDrawer?.cashOut ?? 0)
        .toDecimal()
        .toMoney('', true)}`,
    ]);
    rows.push([
      'Cash Refunds',
      '',
      `-${decimalCalculations(
        data?.paymentsSummary?.cashDrawer?.cashRefunds ?? 0,
      )
        .toDecimal()
        .toMoney('', true)}`,
    ]);
    rows.push([
      'Expected Cash Amount',
      '',
      decimalCalculations(
        data?.paymentsSummary?.cashDrawer?.expectedBalance ?? 0,
      )
        .toDecimal()
        .toMoney('', true),
    ]);
    if (showDifference) {
      rows.push([
        'Actual Cash Amount',
        '',
        decimalCalculations(
          data?.paymentsSummary?.cashDrawer?.closingBalance ?? 0,
        )
          .toDecimal()
          .toMoney('', true),
      ]);
      rows.push([
        'Difference',
        '',
        decimalCalculations(data?.paymentsSummary?.cashDrawer?.closingBalance)
          .minus(data?.paymentsSummary?.cashDrawer?.expectedBalance)
          .toDecimal()
          .toMoney('', true),
      ]);
    }

    // Payments
    rows.push(['', '']);
    rows.push(['Payments', '', '', '']);
    rows.push(['', '', 'Processed', '', 'Refunds', 'Net']);
    rows.push([
      'Total',
      data?.paymentsSummary?.netPayments?.totalProcessedCount ?? 0,
      decimalCalculations(
        data?.paymentsSummary?.netPayments?.totalProcessedAmount ?? 0,
      )
        .toDecimal()
        .toMoney('', true),

      data?.paymentsSummary?.netPayments?.totalRefundedCount ?? 0,
      `-${decimalCalculations(
        data?.paymentsSummary?.netPayments?.totalRefundedAmount ?? 0,
      )
        .toDecimal()
        .toMoney('', true)}`,

      decimalCalculations(
        data?.paymentsSummary?.netPayments?.totalNetAmount ?? 0,
      )
        .toDecimal()
        .toMoney('', true),
    ]);

    rows.push([
      'Card',
      data?.paymentsSummary?.netPayments?.cards?.totalProcessedCount ?? 0,
      decimalCalculations(
        data?.paymentsSummary?.netPayments?.cards?.totalProcessedAmount ?? 0,
      )
        .toDecimal()
        .toMoney('', true),

      data?.paymentsSummary?.netPayments?.cards?.totalRefundedCount ?? 0,
      `-${decimalCalculations(
        data?.paymentsSummary?.netPayments?.cards?.totalRefundedAmount ?? 0,
      )
        .toDecimal()
        .toMoney('', true)}`,

      decimalCalculations(
        data?.paymentsSummary?.netPayments?.cards?.totalNetAmount ?? 0,
      )
        .toDecimal()
        .toMoney('', true),
    ]);
    data?.paymentsSummary?.netPayments?.cards?.data?.forEach((card) => {
      rows.push([
        `  ${card.cardBrand}`,
        card.processedCount,
        decimalCalculations(card.processedAmount ?? 0)
          .toDecimal()
          .toMoney('', true),
        card.refundedCount,
        `-${decimalCalculations(card.refundedAmount ?? 0)
          .toDecimal()
          .toMoney('', true)}`,
        decimalCalculations(card.netAmount ?? 0)
          .toDecimal()
          .toMoney('', true),
      ]);
    });

    rows.push([
      'Cash',
      data?.paymentsSummary?.netPayments?.cash?.processedCount ?? 0,
      decimalCalculations(
        data?.paymentsSummary?.netPayments?.cash?.processedAmount ?? 0,
      )
        .toDecimal()
        .toMoney('', true),

      data?.paymentsSummary?.netPayments?.cash?.refundedCount ?? 0,
      `-${decimalCalculations(
        data?.paymentsSummary?.netPayments?.cash?.refundedAmount ?? 0,
      )
        .toDecimal()
        .toMoney('', true)}`,

      decimalCalculations(
        data?.paymentsSummary?.netPayments?.cash?.netAmount ?? 0,
      )
        .toDecimal()
        .toMoney('', true),
    ]);

    rows.push([
      'Others',
      data?.paymentsSummary?.netPayments?.custom?.totalProcessedCount ?? 0,
      decimalCalculations(
        data?.paymentsSummary?.netPayments?.custom?.totalProcessedAmount ?? 0,
      )
        .toDecimal()
        .toMoney('', true),

      data?.paymentsSummary?.netPayments?.custom?.totalRefundedCount ?? 0,
      `-${decimalCalculations(
        data?.paymentsSummary?.netPayments?.custom?.totalRefundedAmount ?? 0,
      )
        .toDecimal()
        .toMoney('', true)}`,

      decimalCalculations(
        data?.paymentsSummary?.netPayments?.custom?.totalNetAmount ?? 0,
      )
        .toDecimal()
        .toMoney('', true),
    ]);
    data?.paymentsSummary?.netPayments?.custom?.data?.forEach((custom) => {
      rows.push([
        `  ${custom.paymentMethodName}`,
        custom.processedCount,
        decimalCalculations(custom.processedAmount ?? 0)
          .toDecimal()
          .toMoney('', true),
        custom.refundedCount,
        `-${decimalCalculations(custom.refundedAmount ?? 0)
          .toDecimal()
          .toMoney('', true)}`,
        decimalCalculations(custom.netAmount ?? 0)
          .toDecimal()
          .toMoney('', true),
      ]);
    });

    return rows.map((row) => row.join(','));
  };

  return (
    <AppButton
      title="Export"
      onClick={handleExport}
      startIcon={<ArrowOutwardIcon sx={{ ...iconSx }} />}
      disabled={disabled}
      variant="outlined"
      size="small"
      color="secondary"
      sx={{
        height: 39,
        backgroundColor: Colors.white,
        '&:hover': {
          outline: `1px solid ${Colors.primary}`,
          backgroundColor: Colors.white,
        },
        border: `1px solid ${Colors.greyBorder}`,
        color: Colors.black,
        ...buttonSx,
      }}
    />
  );
}

export default ShiftSummaryExportButton;
