import { Navigate, Outlet } from 'react-router-dom';

import { useAppSelector } from '../hooks';
import { selectAuth } from '../redux/selectors/authSelectors';

function RequireNonPos() {
  const { restaurant } = useAppSelector(selectAuth);

  return !restaurant?.isPos ? <Outlet /> : <Navigate to="/" replace />;
}

export default RequireNonPos;
