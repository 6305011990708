import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import arrowBack from '../../../assets/svgs/arrow_back.svg';
import { useAppDispatch } from '../../../hooks';
import { resetCreatingData } from '../redux/discountsSlice';

const CreateDiscountPageLayoutTitle = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleBackClick = () => {
    dispatch(resetCreatingData());
    navigate('/discounts/all-discounts');
  };

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={handleBackClick}>
        <img src={arrowBack} alt="back button icon" />
      </Box>
      <Typography variant={'h4'} sx={{ my: 2 }}>
        Create Discount
      </Typography>
    </Box>
  );
};

export default CreateDiscountPageLayoutTitle;
