import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Outlet } from 'react-router-dom';
import { Colors } from '../theme/colors';
import AppHeader from '../components/AppHeader';

const ArchivedRestaurantLayout = () => {
  return (
    <Box
      sx={{
        background: `linear-gradient(172deg, ${Colors.backgroundLogin} 50%, ${Colors.backgroundPrimary} 50%)`,
      }}>
      <AppHeader />
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          height: '95vh',
        }}>
        <Outlet />
      </Container>
    </Box>
  );
};

export default ArchivedRestaurantLayout;
