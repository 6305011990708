import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import mixpanel from 'mixpanel-browser';

import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import Environment from './config/environment';
import { persistor, store } from './redux/store';
import theme from './theme';
import { StrictMode } from 'react';

const mixpanelToken = Environment.mixpanelToken;

mixpanel.init(mixpanelToken, {
  debug: Environment.isDebug,
  persistence: 'localStorage',
  disable_notifications: !Environment.mixpanelEnabled,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate
        loading={<CircularProgress size="25px" thickness={5} />}
        persistor={persistor}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </StrictMode>,
);
