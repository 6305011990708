import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import correct from '../../../assets/svgs/correct.svg';
import { Colors } from '../../../theme/colors';
import { CREATE_DISCOUNT_STEPS, DISCOUNT_STEP_ID } from '../constants';
import useStepCompletedStatus from '../hooks/useStepCompletedStatus';

type StepperItemProps = {
  id: DISCOUNT_STEP_ID;
  Icon: string;
  SelectedIcon: string;
  title: string;
  isSelected: boolean;
};

const CreateDiscountsStepper = () => {
  const location = useLocation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        padding: '20px',
        backgroundColor: Colors.backgroundSecondary,
        height: '100%',
        borderRadius: 2,
      }}>
      {CREATE_DISCOUNT_STEPS.map((step, index) => (
        <StepperItem
          key={step.id}
          id={step.id}
          isLast={index === CREATE_DISCOUNT_STEPS.length - 1}
          Icon={step.Icon}
          SelectedIcon={step.SelectedIcon}
          title={step.title}
          isSelected={location.pathname === step.path}
        />
      ))}
    </Box>
  );
};

const StepperItem = ({
  id,
  Icon,
  SelectedIcon,
  isSelected,
  title,
  isLast,
}: StepperItemProps & { isLast: boolean }) => {
  const isCompleted = useStepCompletedStatus(id);

  const renderIcon = useCallback(() => {
    if (isCompleted) {
      return correct;
    } else if (isSelected) {
      return SelectedIcon;
    } else {
      return Icon;
    }
  }, [Icon, SelectedIcon, isCompleted, isSelected]);

  const backgroundColor = useMemo(() => {
    if (isCompleted) {
      return Colors.primary;
    } else if (isSelected) {
      return Colors.primaryLight;
    } else {
      return Colors.backgroundPrimary;
    }
  }, [isCompleted, isSelected]);

  const outerBorderValue = useMemo(() => {
    if (isCompleted || isSelected) {
      return `1px solid ${Colors.primary}`;
    } else {
      return 'none';
    }
  }, [isCompleted, isSelected]);

  const textColor = useMemo(() => {
    if (isCompleted || isSelected) {
      return Colors.primary;
    } else {
      return Colors.fontColorPrimary;
    }
  }, [isCompleted, isSelected]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
      }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          padding: '4px',
          border: outerBorderValue,
          borderRadius: 9999,
          maxWidth: 'fit-content',
        }}>
        <Box
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: backgroundColor,
          }}>
          <img src={renderIcon()} alt={title} />
        </Box>

        <Typography color={textColor} mr={3}>
          {title}
        </Typography>
      </Box>

      {!isLast ? (
        <Divider
          orientation="vertical"
          sx={{
            backgroundColor: isCompleted
              ? Colors.primary
              : Colors.backgroundLight,
            height: '14px',
            width: '2px',
            ml: '24px',
          }}
        />
      ) : null}
    </Box>
  );
};

export default CreateDiscountsStepper;
