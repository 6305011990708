import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from '../hooks';
import { selectAuth } from '../redux/selectors/authSelectors';

type Props = {
  /**
   * If true, requires restaurant to be active
   * If false, requires restaurant to be archived
   */
  active: boolean;
};

const WithRestaurantStatus = ({ active }: Props) => {
  const { isArchivedRestaurant } = useAppSelector(selectAuth);

  // If restaurant is undefined, it means it's archived or inaccessible
  const isArchived = isArchivedRestaurant ?? false;

  // If active is true, we want restaurant to be active (not archived)
  // If active is false, we want restaurant to be archived
  const isStatusValid = active === !isArchived;

  if (isStatusValid) {
    return <Outlet />;
  }

  // If restaurant is archived and we require active, redirect to archived page
  if (isArchived && active) {
    return <Navigate to="/restaurant-archived" replace />;
  }

  // If restaurant is active and we require archived, redirect to home
  return <Navigate to="" replace />;
};

export default WithRestaurantStatus;
