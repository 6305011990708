import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import arrowBack from '../../../assets/svgs/arrow_back.svg';

const CreateViewDiscountPageLayoutTitle = () => {
  const navigate = useNavigate();
  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => navigate('/discounts/all-discounts')}>
        <img src={arrowBack} alt="back button icon" />
      </Box>
      <Typography variant={'h4'} sx={{ my: 2 }}>
        Create Discount
      </Typography>
    </Box>
  );
};

export default CreateViewDiscountPageLayoutTitle;
