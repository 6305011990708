import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const AppLoader = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: '92px',
        left: 0,
        right: 0,
        zIndex: 1000,
      }}>
      <LinearProgress
        sx={{
          height: '2px',
        }}
      />
    </Box>
  );
};

export default AppLoader;
