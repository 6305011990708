import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Colors } from '../theme/colors';
import AppDivider from './AppDivider';

type AppAccordionProps = {
  title: string;
  subtitle?: string;
  rightTopText?: string;
  rightBottomText?: string;
  children: React.ReactNode;
  expanded: boolean;
  onChange?: (event: React.SyntheticEvent, expanded: boolean) => void;
};

const AppAccordion = ({
  title,
  subtitle,
  rightTopText,
  rightBottomText,
  children,
  expanded,
  onChange,
}: AppAccordionProps) => {
  const handleChange = (event: React.SyntheticEvent, newExpanded: boolean) => {
    onChange?.(event, newExpanded);
  };

  return (
    <Box sx={{ mb: '8px' }}>
      <Accordion
        elevation={0}
        expanded={expanded}
        onChange={handleChange}
        sx={{
          mb: expanded ? 28 : 1,
          border: `2px solid ${
            expanded ? Colors.primaryFade : Colors.greyBorderLogin
          }`,
          boxShadow: 'none',
        }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Box>
              <Typography
                data-testid={`${title}-title`}
                sx={{
                  fontWeight: 600,
                  fontSize: '20px',
                  color: Colors.primary,
                }}>
                {title}
              </Typography>
              {subtitle && !expanded && (
                <Typography
                  data-testid={`${title}-subtitle`}
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    mt: 1,
                    color: Colors.fontColorSecondary,
                  }}>
                  {subtitle}
                </Typography>
              )}
            </Box>
            {rightTopText && !expanded && (
              <Box sx={{ textAlign: 'right', mr: 1 }}>
                <Typography
                  data-testid={`${title}-rightTopText`}
                  sx={{
                    fontWeight: 400,
                    fontSize: '15px',
                    color: Colors.fontColorSecondary,
                    mt: 1,
                  }}>
                  {rightTopText}
                </Typography>
                {rightBottomText && (
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: '18px',
                      color: Colors.fontColorPrimary,
                      mt: 1,
                    }}>
                    {rightBottomText}
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ pt: 0, mt: '-9px' }}>
          {expanded && (
            <AppDivider
              style={{
                borderColor: Colors.greyBorderLogin,
                borderTopStyle: 'solid',
                width: '100%',
                mb: 2,
              }}
            />
          )}
          {children}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default AppAccordion;
