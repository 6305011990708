import AppVerticalTabs from '../../../components/AppVerticalTabs';
import { RESTAURANT_PAYMENT_PROVIDER } from '../../../constants';
import {
  useAppDispatch,
  useAppSelector,
  useTabNavigation,
} from '../../../hooks';
import { selectAuth } from '../../../redux/selectors/authSelectors';
import { resetMenuPaginationState } from '../../menu/redux/menuPaginationSlice';
import {
  ADYEN_PAYMENT_RECONCILIATION_REPORT_NAVIGATION_TABS,
  ADYEN_PAYMENT_RECONCILIATION_REPORT_TABS,
  PAYMENT_RECONCILIATION_REPORT_NAVIGATION_TABS,
  PAYMENT_RECONCILIATION_REPORT_TABS,
} from '../constants/report.tabs.contants';

function PaymentReconciliationTabs() {
  const dispatch = useAppDispatch();
  const { restaurant } = useAppSelector(selectAuth);
  const isAdyen =
    restaurant?.paymentProvider === RESTAURANT_PAYMENT_PROVIDER.ADYEN;

  const tabs = isAdyen
    ? ADYEN_PAYMENT_RECONCILIATION_REPORT_TABS
    : PAYMENT_RECONCILIATION_REPORT_TABS;

  const { currentTab, handleTabChange } = useTabNavigation(
    tabs,
    isAdyen
      ? ADYEN_PAYMENT_RECONCILIATION_REPORT_NAVIGATION_TABS.DAILY_ADYEN_SUMMARY
      : PAYMENT_RECONCILIATION_REPORT_NAVIGATION_TABS.DAILY_STRIPE_SUMMARY,
  );

  return (
    <AppVerticalTabs
      tabs={tabs}
      currentTab={currentTab}
      horizontal
      handleTabChange={(tab) => {
        dispatch(resetMenuPaginationState());
        handleTabChange(tab);
      }}
    />
  );
}

export default PaymentReconciliationTabs;
