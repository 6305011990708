import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import StorefrontIcon from '@mui/icons-material/Storefront';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import debounce from 'lodash/debounce';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import eatMeLogo from '../../../assets/logo.png';
import {
  changeRestaurant,
  setIsChangingRestaurant,
} from '../../../features/auth/redux/authSlice';
import { IAuthRestaurant } from '../../../features/auth/types/auth.types';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { Colors } from '../../../theme/colors';
import { selectAuth } from '../../../redux/selectors/authSelectors';
import { alpha } from '@mui/material';

const RestaurantsDropdown = () => {
  const navigate = useNavigate();
  const reactLocation = useLocation();
  const dispatch = useAppDispatch();

  const { restaurant, user, isChangingRestaurant } = useAppSelector(selectAuth);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (user?.restaurants && user.restaurants.length > 1) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
      navigate('/');
    }
  };

  const handleCloseUserMenu = () => {
    setAnchorEl(null);
  };

  const debouncedOnClickRestaurant = useMemo(
    () =>
      debounce(
        async (restaurantDetails: IAuthRestaurant) => {
          dispatch(setIsChangingRestaurant(true));
          await dispatch(changeRestaurant({ resId: restaurantDetails.id }));
          dispatch(setIsChangingRestaurant(false));
          navigate('/');
        },
        1000,
        { leading: true, trailing: false },
      ),
    [dispatch, navigate],
  );

  const onClickRestaurant = async (restaurantDetails: IAuthRestaurant) => {
    setAnchorEl(null);
    await debouncedOnClickRestaurant(restaurantDetails);
  };

  return (
    <Box>
      {!reactLocation.pathname.includes('settings') ? (
        <Backdrop
          open={isChangingRestaurant}
          sx={{
            zIndex: 999,
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            color: 'transparent',
            backgroundColor: alpha(Colors.backgroundSecondary, 0.5),
          }}>
          <CircularProgress size="25px" thickness={5} />
        </Backdrop>
      ) : null}
      <Button
        onClick={handleOpenMenu}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 0,
          px: 1,
          textTransform: 'none',
          color: Colors.fontColorPrimary,
        }}>
        <Avatar
          sx={{
            width: '30px',
            height: '30px',
            borderWidth: '1px',
            borderColor: Colors.borderPrimary,
            borderStyle: 'solid',
          }}
          src={restaurant?.logo ?? eatMeLogo}
          alt="logo"
        />
        <Typography variant="body2" sx={{ ml: 1 }}>
          {isChangingRestaurant
            ? 'Loading...'
            : (restaurant?.name ?? 'Select a restaurant')}
        </Typography>
        {user?.restaurants && user.restaurants.length > 1 ? (
          <KeyboardArrowDownIcon
            sx={{
              ml: 1,
            }}
          />
        ) : null}
      </Button>
      <Menu
        keepMounted
        id="app-header-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={handleCloseUserMenu}
        sx={{ mt: '25px' }}>
        {user?.restaurants?.map((restaurant, index) => (
          <MenuItem
            key={`${restaurant.id}-${index}`}
            onClick={() => onClickRestaurant(restaurant)}
            data-testid={`menu-item-${restaurant.id}`}>
            {restaurant?.logo ? (
              <Avatar
                sx={{
                  borderRadius: '50%',
                  width: '25px',
                  height: '25px',
                }}
                src={restaurant.logo}
                alt="logo"
              />
            ) : (
              <StorefrontIcon />
            )}

            <Typography
              textAlign="center"
              sx={{
                ml: 2,
              }}>
              {restaurant.name}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default RestaurantsDropdown;
