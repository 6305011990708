import { Navigate, Outlet } from 'react-router-dom';

import { useAppSelector } from '../hooks';
import { selectAuth } from '../redux/selectors/authSelectors';

const NoAuthAccess = () => {
  const { token } = useAppSelector(selectAuth);

  return token ? <Navigate to="/" replace /> : <Outlet />;
};

export default NoAuthAccess;
