import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { logout } from '../../features/auth/redux/authSlice';
import { useAppDispatch } from '../../hooks';
import { Colors } from '../../theme/colors';
import AppHeaderDropdown, {
  IHeaderDropdownItem,
} from './components/ProfileDropdown';
import RestaurantsDropdown from './components/RestaurantsDropdown';

const PROFILE_DROPDOWN: Record<string, IHeaderDropdownItem> = {
  LOGOUT: {
    key: 'logout',
    name: 'Log out',
    icon: <LogoutIcon />,
  },
};

const AppHeader = () => {
  const dispatch = useAppDispatch();

  const handleOnClickProfileMenu = (item: IHeaderDropdownItem) => {
    switch (item.key) {
      case PROFILE_DROPDOWN.LOGOUT.key:
        dispatch(logout());
        break;

      case PROFILE_DROPDOWN.PROFILE.key:
        // TODO: navigate to profile
        break;

      default:
        break;
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: Colors.backgroundSecondary,
        position: 'sticky',
        top: 0,
        zIndex: 1000,
        width: '100%',
        height: '45px',
      }}
      data-testid="app-header">
      <Container
        maxWidth="xl"
        sx={{
          height: 45,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <RestaurantsDropdown />
        <Box>
          <AppHeaderDropdown
            icon={<PersonIcon />}
            tooltip="Account"
            onClick={handleOnClickProfileMenu}
            items={Object.values(PROFILE_DROPDOWN)}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default AppHeader;
