import { Navigate, Outlet } from 'react-router';
import { RESTAURANT_PAYMENT_PROVIDER } from '../constants';
import { useAppSelector } from '../hooks';
import { selectAuth } from '../redux/selectors/authSelectors';

const RequireStripePayments = () => {
  const { restaurant } = useAppSelector(selectAuth);

  if (restaurant?.paymentProvider === RESTAURANT_PAYMENT_PROVIDER.STRIPE) {
    return <Outlet />;
  }

  return <Navigate replace to="" />;
};

export default RequireStripePayments;
