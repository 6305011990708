import { useMemo } from 'react';
import AppVerticalTabs from '../../../components/AppVerticalTabs';
import { useAppSelector, useTabNavigation } from '../../../hooks';
import { selectAuth } from '../../../redux/selectors/authSelectors';
import {
  SETTINGS_NAVIGATION_TABS,
  SETTINGS_TABS,
} from '../constants/tabs.constants';
import {
  ADYEN_LEGAL_ENTITY_STATUS,
  RESTAURANT_PAYMENT_PROVIDER,
} from '../../../constants';

/**
 * SettingsTabs Component
 *
 * Controls settings tab visibility based on Adyen account status:
 * - Account tab is shown for onboarded accounts (SUCCESS, ACTION_REQUIRED, SUCCESS_REVIEW_PENDING)
 * - General Settings, Payment Type, and Devices tabs are always visible for Adyen providers
 * - Non-Adyen providers have their own tab configuration
 *
 */
const SettingsTabs = () => {
  const { restaurant } = useAppSelector(selectAuth);

  const FILTERED_SETTINGS_TABS = useMemo(() => {
    const isAdyenProvider =
      restaurant?.paymentProvider === RESTAURANT_PAYMENT_PROVIDER.ADYEN;
    const adyenStatus = restaurant?.adyenAccount?.status;

    // Show Account tab for successfully onboarded accounts
    const showAccountTab =
      adyenStatus === ADYEN_LEGAL_ENTITY_STATUS.SUCCESS ||
      adyenStatus === ADYEN_LEGAL_ENTITY_STATUS.ACTION_REQUIRED ||
      adyenStatus === ADYEN_LEGAL_ENTITY_STATUS.SUCCESS_REVIEW_PENDING;

    if (!isAdyenProvider) {
      return SETTINGS_TABS.filter(
        (tab) => tab.id !== SETTINGS_NAVIGATION_TABS.ACCOUNT,
      );
    }

    // Base tabs always visible for Adyen providers
    const baseAdyenTabs = SETTINGS_TABS.filter(
      (tab) =>
        tab.id === SETTINGS_NAVIGATION_TABS.GENERAL_SETTINGS ||
        tab.id === SETTINGS_NAVIGATION_TABS.PAYMENT_TYPE ||
        tab.id === SETTINGS_NAVIGATION_TABS.DEVICES,
    );

    if (showAccountTab) {
      // Maintain specific tab order with Account tab between General Settings and Payment Type
      return [
        ...SETTINGS_TABS.filter(
          (tab) => tab.id === SETTINGS_NAVIGATION_TABS.GENERAL_SETTINGS,
        ),
        ...SETTINGS_TABS.filter(
          (tab) => tab.id === SETTINGS_NAVIGATION_TABS.ACCOUNT,
        ),
        ...SETTINGS_TABS.filter(
          (tab) =>
            tab.id === SETTINGS_NAVIGATION_TABS.PAYMENT_TYPE ||
            tab.id === SETTINGS_NAVIGATION_TABS.DEVICES,
        ),
      ];
    }

    return baseAdyenTabs;
  }, [restaurant]);

  const { currentTab, handleTabChange } = useTabNavigation(
    FILTERED_SETTINGS_TABS,
    SETTINGS_NAVIGATION_TABS.GENERAL_SETTINGS,
  );

  return (
    <AppVerticalTabs
      tabs={FILTERED_SETTINGS_TABS}
      currentTab={currentTab}
      handleTabChange={handleTabChange}
    />
  );
};

export default SettingsTabs;
