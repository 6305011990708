import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { ReactNode, useState } from 'react';

import version from '../../../assets/svgs/version.svg';
import Environment from '../../../config/environment';
import { useAppSelector } from '../../../hooks';
import { selectAuth } from '../../../redux/selectors/authSelectors';
import { Colors } from '../../../theme/colors';
import { getRoleName } from '../../../utils';

export interface IHeaderDropdownItem {
  key: string;
  name: string;
  icon?: ReactNode;
}

type ProfileDropdownProps = {
  items: IHeaderDropdownItem[];
  icon: ReactNode;
  tooltip: string;
  onClick: (item: IHeaderDropdownItem) => void;
};

const AppHeaderDropdown = ({
  items,
  icon,
  onClick,
  tooltip,
}: ProfileDropdownProps) => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const { user } = useAppSelector(selectAuth);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuItem = async (item: IHeaderDropdownItem) => {
    onClick(item);
    setAnchorElUser(null);
  };

  return (
    <Box>
      <Tooltip title={tooltip}>
        <IconButton
          onClick={handleOpenMenu}
          sx={{
            backgroundColor: Colors.backgroundSecondary,
            p: 0.5,
          }}>
          {icon}
        </IconButton>
      </Tooltip>

      <Menu
        keepMounted
        id="app-header-menu"
        anchorEl={anchorElUser}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        sx={{ mt: '25px' }}>
        {user?.name && (
          <MenuItem
            disableGutters
            disableTouchRipple
            disableRipple
            disabled
            sx={{
              mb: '-5px',
              '&.Mui-disabled': {
                opacity: 1,
              },
            }}>
            <Box
              sx={{
                px: 2,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}>
              <Typography
                variant="body2"
                color={Colors.black}
                fontWeight={600}
                sx={{ mb: 1 }}>
                {user?.name}
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color: Colors.fontColorSecondary,
                  lineHeight: '12px',
                  mb: 1,
                }}>
                {user?.email}
              </Typography>
              <Box
                sx={{
                  px: 2,
                  py: '2px',
                  background: Colors.primaryLight,
                  display: 'flex',
                  borderRadius: '5px',
                }}>
                <Typography
                  variant="caption"
                  sx={{ color: Colors.primary, fontWeight: 500 }}>
                  {getRoleName(user?.role)}
                </Typography>
              </Box>
              <Divider />
            </Box>
          </MenuItem>
        )}

        <Divider />

        <MenuItem
          disableGutters
          disableTouchRipple
          disableRipple
          disabled
          sx={{
            display: 'flex',
            alignItems: 'left',
            justifyContent: 'left',
            maxHeight: '25px',
            '&.Mui-disabled': {
              opacity: 1,
            },
          }}>
          <img src={version} alt="version" style={{ marginLeft: 15 }} />
          <Typography
            textAlign="center"
            sx={{
              ml: 2,
              fontWeight: 400,
            }}>
            v{Environment.version}{' '}
          </Typography>
        </MenuItem>
        <Divider />
        {items.map((item) => (
          <MenuItem
            key={item.key}
            onClick={() => handleMenuItem(item)}
            data-testid={`menu-item-${item.key}`}
            sx={{
              color: Colors.failed,
            }}>
            {item.icon}
            <Typography
              textAlign="center"
              sx={{
                ml: item.icon ? 2 : 0,
                fontWeight: 500,
              }}>
              {item.name}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default AppHeaderDropdown;
