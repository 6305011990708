import { IVerticalTabItem } from '../../../components/AppVerticalTabs';

export enum PAYMENT_RECONCILIATION_REPORT_NAVIGATION_TABS {
  SUMMARY = 'detailed_summary',
  DAILY_STRIPE_SUMMARY = 'daily_summary',
}

export enum ADYEN_PAYMENT_RECONCILIATION_REPORT_NAVIGATION_TABS {
  SUMMARY = 'detailed_summary',
  DAILY_ADYEN_SUMMARY = 'daily_summary',
}

export enum SHIFT_REPORT_NAVIGATION_TABS {
  DETAILED = 'detailed',
  CONSOLIDATED = 'consolidated',
}

export const PAYMENT_RECONCILIATION_REPORT_TABS: IVerticalTabItem[] = [
  {
    id: PAYMENT_RECONCILIATION_REPORT_NAVIGATION_TABS.DAILY_STRIPE_SUMMARY,
    label: 'Daily Summary',
    path: '/reports/payment-reconciliation-summary/daily-summary',
  },
  {
    id: PAYMENT_RECONCILIATION_REPORT_NAVIGATION_TABS.SUMMARY,
    label: 'Detailed Summary',
    path: '/reports/payment-reconciliation-summary/detailed-summary',
  },
];

export const ADYEN_PAYMENT_RECONCILIATION_REPORT_TABS: IVerticalTabItem[] = [
  {
    id: ADYEN_PAYMENT_RECONCILIATION_REPORT_NAVIGATION_TABS.DAILY_ADYEN_SUMMARY,
    label: 'Daily Summary',
    path: '/reports/payment-reconciliation-summary/daily-summary',
  },
  {
    id: ADYEN_PAYMENT_RECONCILIATION_REPORT_NAVIGATION_TABS.SUMMARY,
    label: 'Detailed Summary',
    path: '/reports/payment-reconciliation-summary/detailed-summary',
  },
];

export const SHIFT_REPORT_TABS: IVerticalTabItem[] = [
  {
    id: SHIFT_REPORT_NAVIGATION_TABS.DETAILED,
    label: 'Detailed',
    path: '/reports/shifts-summary/detailed',
  },
  {
    id: SHIFT_REPORT_NAVIGATION_TABS.CONSOLIDATED,
    label: 'Consolidated',
    path: '/reports/shifts-summary/consolidated-shifts-summary',
  },
];
