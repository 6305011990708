import { Navigate, Outlet, useSearchParams } from 'react-router-dom';

const RequireSearchParamToken = () => {
  const [searchParams] = useSearchParams();

  const hasValidSearchToken = searchParams.get('token');

  return !hasValidSearchToken ? <Navigate to="/" replace /> : <Outlet />;
};

export default RequireSearchParamToken;
