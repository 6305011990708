import Grid from '@mui/material/Grid';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';

import SearchIcon from '../../../assets/searchIcon.png';
import AppTextInput from '../../../components/AppTextInput';
import {
  useAppDispatch,
  useAppSelector,
  useTabNavigation,
} from '../../../hooks';
import { selectMenuPagination } from '../../../redux/selectors/menuPaginationSelectors';
import {
  MENU_NAVIGATION_NESTED_TABS,
  MENU_NAVIGATION_TABS,
  MENU_TABS,
} from '../constants';
import { fetchAddOnsData } from '../redux/addOnGroupSlice';
import { fetchCategoriesData } from '../redux/categoriesSlice';
import { fetchChoiceGroupData } from '../redux/choiceGroupSlice';
import { fetchDishData } from '../redux/dishesSlice';
import {
  setMenuPaginationLimit,
  setMenuPaginationSearchKey,
  setMenuPaginationSkip,
} from '../redux/menuPaginationSlice';
import { fetchPageData } from '../redux/pagesSlice';
import { fetchSubCategoriesData } from '../redux/subCategoriesSlice';
import { ENTITY_SORT } from '../types/common.types';

const MenuHeader = () => {
  const dispatch = useAppDispatch();
  const { search_key: searchKey } = useAppSelector(selectMenuPagination);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setSearchTerm(searchKey ?? '');
  }, [searchKey]);

  const { currentTab, currentNestedTab } = useTabNavigation<
    MENU_NAVIGATION_TABS,
    MENU_NAVIGATION_NESTED_TABS
  >(MENU_TABS, MENU_NAVIGATION_TABS.PAGES, MENU_NAVIGATION_NESTED_TABS.CHOICES);

  const placeholders: Record<string, string> = {
    [MENU_NAVIGATION_TABS.PAGES]: 'Search Pages...',
    [MENU_NAVIGATION_TABS.DISHES]: 'Search Items...',
    [MENU_NAVIGATION_TABS.CATEGORIES]: 'Search Categories...',
    [MENU_NAVIGATION_TABS.SUB_CATEGORIES]: 'Search Subcategories...',
    [MENU_NAVIGATION_TABS.MODIFIERS]: 'Search modifiers...',
  };

  const debouncedSearch = useMemo(() => {
    return debounce(
      async (searchTerm: string) => {
        dispatch(setMenuPaginationSkip(0));
        dispatch(setMenuPaginationLimit(10));
        dispatch(setMenuPaginationSearchKey(searchTerm));

        if (currentTab === MENU_NAVIGATION_TABS.PAGES) {
          await dispatch(
            fetchPageData({
              sort_by: 'sequenceNumber',
              sort_order: ENTITY_SORT.ASC,
            }),
          );
        } else if (currentTab === MENU_NAVIGATION_TABS.DISHES) {
          await dispatch(
            fetchDishData({
              sort_by: 'sequenceNumber',
              sort_order: ENTITY_SORT.ASC,
            }),
          );
        } else if (currentTab === MENU_NAVIGATION_TABS.CATEGORIES) {
          await dispatch(
            fetchCategoriesData({
              sort_by: 'sequenceNumber',
              sort_order: ENTITY_SORT.ASC,
            }),
          );
        } else if (currentTab === MENU_NAVIGATION_TABS.SUB_CATEGORIES) {
          await dispatch(
            fetchSubCategoriesData({
              sort_by: 'sequenceNumber',
              sort_order: ENTITY_SORT.ASC,
            }),
          );
        } else if (currentTab === MENU_NAVIGATION_TABS.MODIFIERS) {
          if (currentNestedTab === MENU_NAVIGATION_NESTED_TABS.CHOICES) {
            await dispatch(
              fetchChoiceGroupData({
                sort_by: 'sequenceNumber',
                sort_order: ENTITY_SORT.ASC,
              }),
            );
          } else if (currentNestedTab === MENU_NAVIGATION_NESTED_TABS.ADDONS) {
            await dispatch(
              fetchAddOnsData({
                sort_by: 'sequenceNumber',
                sort_order: ENTITY_SORT.ASC,
              }),
            );
          }
        }
      },
      500,
      { leading: false, trailing: true },
    );
  }, [currentTab, dispatch, currentNestedTab]);

  const handleSearch = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(e.target.value);
      await debouncedSearch(e.target.value);
    },
    [debouncedSearch],
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={3}>
        <AppTextInput
          placeholder={placeholders[currentTab]}
          icon={<img src={SearchIcon} alt="search" />}
          value={searchTerm}
          onChange={handleSearch}
        />
      </Grid>
    </Grid>
  );
};

export default MenuHeader;
