import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import theme from '../theme';

const pathsNotToScale = [
  '/login',
  '/register',
  '/register/success',
  '/invite',
  '/invite/invalid',
];

const useWindowResize = () => {
  const location = useLocation();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [zoom, setZoom] = useState(1);

  useEffect(() => {
    const metaTag = document.querySelector('meta[name="viewport"]');
    if (metaTag) {
      if (pathsNotToScale.includes(location.pathname)) {
        metaTag.setAttribute('content', 'width=device-width, initial-scale=1');
      } else {
        metaTag.setAttribute('content', 'width=1024');
      }
    }

    const handleResize = () => {
      if (!pathsNotToScale.includes(location.pathname)) {
        const windowWidth = window.innerWidth;
        const scaleFactor = isLargeScreen ? 1 : windowWidth / 1024;
        setZoom(scaleFactor);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isLargeScreen, location]);
  return zoom;
};

export default useWindowResize;
