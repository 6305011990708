import ClearIcon from '@mui/icons-material/Clear';
import Grid from '@mui/material/Grid';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';

import SearchIcon from '../../../assets/searchIcon.png';
import AppTextInput from '../../../components/AppTextInput';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useTabNavigation } from '../../../hooks/useTabNavigation';
import { selectDiscountsPagination } from '../../../redux/selectors/discountsPaginationSelectors';
import { DISCOUNT_NAVIGATION_TABS, DISCOUNT_TABS } from '../constants';
import {
  setDiscountsPaginationLimit,
  setDiscountsPaginationSearchKey,
  setDiscountsPaginationSkip,
} from '../redux/discountsPaginationSlice';
import { fetchDiscountsData } from '../redux/discountsSlice';

const DiscountsHeader = () => {
  const dispatch = useAppDispatch();
  const { search_key: searchKey } = useAppSelector(selectDiscountsPagination);
  const [searchTerm, setSearchTerm] = useState<string | undefined>('');
  const [icon, setIcon] = useState(<img src={SearchIcon} alt="search" />);

  useEffect(() => {
    setSearchTerm(searchKey ?? '');
  }, [searchKey]);

  const { currentTab } = useTabNavigation<
    DISCOUNT_NAVIGATION_TABS,
    DISCOUNT_NAVIGATION_TABS.DISCOUNTS
  >(DISCOUNT_TABS, DISCOUNT_NAVIGATION_TABS.DISCOUNTS);

  const placeholders: Record<string, string> = {
    [DISCOUNT_NAVIGATION_TABS.DISCOUNTS]: 'Search Discounts...',
    //TODO: Hidden temporally [DISCOUNT_NAVIGATION_TABS.DISCOUNT_REASONS]: 'Search Discount Reasons...',
  };

  const debouncedSearch = useMemo(() => {
    return debounce(
      async (searchKeyWord: string | undefined) => {
        dispatch(setDiscountsPaginationSkip(0));
        dispatch(setDiscountsPaginationLimit(10));
        dispatch(setDiscountsPaginationSearchKey(searchKeyWord));

        if (currentTab === DISCOUNT_NAVIGATION_TABS.DISCOUNTS) {
          await dispatch(fetchDiscountsData());
        }
        //TODO: Hidden temporally else if (currentTab === DISCOUNT_NAVIGATION_TABS.DISCOUNT_REASONS) {
        //   await dispatch(fetchDiscountReasonsData());
        // }
      },
      500,
      { leading: false, trailing: true },
    );
  }, [currentTab, dispatch]);

  const handleSearch = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(e.target.value);
      await debouncedSearch(e.target.value);
      setIcon(
        e.target.value ? <ClearIcon /> : <img src={SearchIcon} alt="search" />,
      );
    },
    [debouncedSearch],
  );

  const handleClear = useCallback(async () => {
    setSearchTerm(undefined);
    await debouncedSearch(undefined);
    setIcon(<img src={SearchIcon} alt="search" />);
  }, [debouncedSearch]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={3}>
        <AppTextInput
          placeholder={placeholders[currentTab]}
          icon={icon}
          value={searchTerm}
          onClickIcon={handleClear}
          onChange={handleSearch}
        />
      </Grid>
    </Grid>
  );
};

export default DiscountsHeader;
