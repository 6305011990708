import AppVerticalTabs from '../../../components/AppVerticalTabs';
import { useAppDispatch, useTabNavigation } from '../../../hooks';
import {
  MENU_NAVIGATION_NESTED_TABS,
  MENU_NAVIGATION_TABS,
  MENU_TABS,
} from '../constants';
import { resetMenuPaginationState } from '../redux/menuPaginationSlice';

const MenuTabs = () => {
  const dispatch = useAppDispatch();
  const { currentTab, currentNestedTab, handleTabChange } = useTabNavigation(
    MENU_TABS,
    MENU_NAVIGATION_TABS.PAGES,
    MENU_NAVIGATION_NESTED_TABS.CHOICES,
  );

  return (
    <AppVerticalTabs
      tabs={MENU_TABS}
      currentTab={currentTab}
      currentNestedTab={currentNestedTab}
      handleTabChange={(tab) => {
        dispatch(resetMenuPaginationState());
        handleTabChange(tab);
      }}
    />
  );
};

export default MenuTabs;
