import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Outlet } from 'react-router-dom';

import { Colors } from '../theme/colors';

const OuterLayout = () => {
  return (
    <Box
      sx={{
        background: `linear-gradient(172deg, ${Colors.backgroundLogin} 50%, ${Colors.backgroundPrimary} 50%)`,
      }}>
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          height: '100vh',
        }}>
        <Outlet />
      </Container>
    </Box>
  );
};

export default OuterLayout;
