import { Navigate, Outlet } from 'react-router';
import { useAppSelector } from '../hooks';
import { selectAuth } from '../redux/selectors/authSelectors';
import { RESTAURANT_PAYMENT_PROVIDER } from '../constants';

const RequireAdyenPayments = () => {
  const { restaurant } = useAppSelector(selectAuth);

  if (
    restaurant?.paymentProvider === RESTAURANT_PAYMENT_PROVIDER.ADYEN &&
    restaurant?.isPos &&
    restaurant?.paymentsOnly
  ) {
    return <Outlet />;
  }

  return <Navigate replace to="" />;
};

export default RequireAdyenPayments;
