import { useLocation } from 'react-router';

import AppVerticalTabs, {
  IVerticalTabItem,
} from '../../../components/AppVerticalTabs';
import { useTabNavigation } from '../../../hooks';
import { SINGLE_SHIFT_SUMMARY_TABS } from '../constants';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { selectShiftSummary } from '../../../redux/selectors/shiftSummarySelectors';

const ConsolidatedShiftSummaryTabs = () => {
  const location = useLocation();
  const { consolidatedShiftSummaryData } = useAppSelector(selectShiftSummary);

  const hideCategoryComponent =
    consolidatedShiftSummaryData?.orderSummary?.categoryDetails
      ?.hideCategoryComponent;

  const SHIFT_SUMMARY_TABS: IVerticalTabItem[] = [
    {
      id: SINGLE_SHIFT_SUMMARY_TABS.SHIFTS,
      label: 'Shifts',
      path: `${location.pathname}#shifts`,
    },
    {
      id: SINGLE_SHIFT_SUMMARY_TABS.SALES,
      label: 'Sales',
      path: `${location.pathname}#sales`,
    },

    ...(hideCategoryComponent
      ? []
      : [
          {
            id: SINGLE_SHIFT_SUMMARY_TABS.CATEGORY_SALES,
            label: 'Category Sales',
            path: `${location.pathname}#category-sales`,
          },
        ]),
    {
      id: SINGLE_SHIFT_SUMMARY_TABS.SERVICE_CHARGE,
      label: 'Service Charge',
      path: `${location.pathname}#service-charge`,
    },
    {
      id: SINGLE_SHIFT_SUMMARY_TABS.TAXES,
      label: 'Taxes',
      path: `${location.pathname}#taxes`,
    },
    {
      id: SINGLE_SHIFT_SUMMARY_TABS.DISCOUNTS,
      label: 'Discounts',
      path: `${location.pathname}#discounts`,
    },
    {
      id: SINGLE_SHIFT_SUMMARY_TABS.VOIDS,
      label: 'Voids',
      path: `${location.pathname}#voids`,
    },
    {
      id: SINGLE_SHIFT_SUMMARY_TABS.CASH_SUMMARY,
      label: 'Cash Summary',
      path: `${location.pathname}#cash-summary`,
    },
    {
      id: SINGLE_SHIFT_SUMMARY_TABS.PAYMENTS,
      label: 'Payments',
      path: `${location.pathname}#payments`,
    },
  ];

  const { currentTab, handleTabChange } = useTabNavigation(
    SHIFT_SUMMARY_TABS,
    SINGLE_SHIFT_SUMMARY_TABS.SHIFTS,
  );

  return (
    <AppVerticalTabs
      tabs={SHIFT_SUMMARY_TABS}
      currentTab={currentTab}
      handleTabChange={handleTabChange}
    />
  );
};

export default ConsolidatedShiftSummaryTabs;
