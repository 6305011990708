import { Box, Grid, Typography } from '@mui/material';

import { Colors } from '../../../theme/colors';

interface InfoProps {
  categoryName?: string;
  itemSalesCount?: string | number;
  grossAmount?: string | number;
  discountAmount?: string | number;
  netGstAmount?: string | number;
  refundedGstAmount?: string | number;
  refundsAmount?: string | number;
  categoryNameSx?: React.CSSProperties;
  itemSalesCountSx?: React.CSSProperties;
  grossAmountSx?: React.CSSProperties;
  discountAmountSx?: React.CSSProperties;
  netGstAmountSx?: React.CSSProperties;
  refundedGstAmountSx?: React.CSSProperties;
  refundsAmountSx?: React.CSSProperties;
  refreshing?: boolean;
}

const CategorySalesRowInfo = ({
  refreshing = false,
  categoryName,
  itemSalesCount,
  grossAmount,
  discountAmount,
  netGstAmount,
  refundedGstAmount,
  refundsAmount,
  categoryNameSx,
  itemSalesCountSx,
  grossAmountSx,
  discountAmountSx,
  netGstAmountSx,
  refundedGstAmountSx,
  refundsAmountSx,
}: InfoProps) => {
  return netGstAmount ? (
    <Box>
      <Grid
        container
        spacing={0}
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
        }}>
        <Grid item xs={3} sx={{ mb: 1 }}>
          <Typography
            sx={{
              color: Colors.black,
              fontWeight: 400,
              ...categoryNameSx,
            }}>
            {categoryName}
          </Typography>
        </Grid>

        <Grid
          item
          xs={1.5}
          sx={{
            display: 'flex',
            justifyContent: 'right',
            mb: 1,
          }}>
          <Typography
            sx={{
              color: Colors.black,
              fontWeight: 400,
              ...itemSalesCountSx,
            }}>
            {refreshing ? '' : itemSalesCount}
          </Typography>
        </Grid>

        <Grid item xs={1.5} sx={{ display: 'flex', justifyContent: 'right' }}>
          <Typography
            sx={{
              color: Colors.black,
              fontWeight: 400,
              mb: 1,
              ...grossAmountSx,
            }}>
            {refreshing ? '' : grossAmount}
          </Typography>
        </Grid>

        <Grid item xs={1.5} sx={{ display: 'flex', justifyContent: 'right' }}>
          <Typography
            sx={{
              color: Colors.black,
              fontWeight: 400,
              mb: 1,
              ...discountAmountSx,
            }}>
            {refreshing ? '' : discountAmount}
          </Typography>
        </Grid>

        <Grid
          item
          xs={1.5}
          sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Typography
            sx={{
              color: Colors.black,
              fontWeight: 400,
              mb: 1,
              ...netGstAmountSx,
            }}>
            {refreshing ? '' : netGstAmount}
          </Typography>
        </Grid>

        <Grid
          item
          xs={1.5}
          sx={{
            display: 'flex',
            justifyContent: 'right',
            textAlign: 'right',
            mb: 1,
          }}>
          <Typography
            sx={{
              color: Colors.black,
              fontWeight: 400,
              mb: 1,
              ...refundedGstAmountSx,
            }}>
            {refreshing ? '' : refundedGstAmount}
          </Typography>
        </Grid>

        <Grid
          item
          xs={1.5}
          sx={{ display: 'flex', justifyContent: 'right', mb: 1 }}>
          <Typography
            sx={{
              color: Colors.black,
              fontWeight: 400,
              ...refundsAmountSx,
            }}>
            {refreshing ? '' : refundsAmount}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  ) : (
    <Box>
      <Grid
        container
        spacing={0}
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
        }}>
        <Grid item xs={2} sx={{ mb: 1 }}>
          <Typography
            sx={{
              color: Colors.black,
              fontWeight: 400,
              ...categoryNameSx,
            }}>
            {categoryName}
          </Typography>
        </Grid>

        <Grid
          item
          xs={1}
          sx={{
            display: 'flex',
            justifyContent: 'right',
            mb: 1,
          }}>
          <Typography
            sx={{
              color: Colors.black,
              fontWeight: 400,
              ...itemSalesCountSx,
            }}>
            {refreshing ? '' : itemSalesCount}
          </Typography>
        </Grid>

        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'right' }}>
          <Typography
            sx={{
              color: Colors.black,
              fontWeight: 400,
              mb: 1,
              ...grossAmountSx,
            }}>
            {refreshing ? '' : grossAmount}
          </Typography>
        </Grid>

        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'right' }}>
          <Typography
            sx={{
              color: Colors.black,
              fontWeight: 400,
              mb: 1,
              ...discountAmountSx,
            }}>
            {refreshing ? '' : discountAmount}
          </Typography>
        </Grid>

        <Grid
          item
          xs={3}
          sx={{ display: 'flex', justifyContent: 'right', mb: 1 }}>
          <Typography
            sx={{
              color: Colors.black,
              fontWeight: 400,
              ...refundsAmountSx,
            }}>
            {refreshing ? '' : refundsAmount}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CategorySalesRowInfo;
