import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';

import { Colors } from '../../../theme/colors';

interface InfoProps {
  label: string | ReactNode;
  value: string | number;
  sum?: boolean;
  qty?: string;
  labelSx?: React.CSSProperties;
  valueSx?: React.CSSProperties;
  qtySx?: React.CSSProperties;
  refreshing?: boolean;
}

const RowInfo = ({
  refreshing = false,
  label,
  value,
  qty,
  labelSx,
  valueSx,
  qtySx,
}: InfoProps) => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6} sx={{ mb: 1 }}>
          {typeof label === 'string' ? (
            <Typography
              sx={{
                color: Colors.black,
                fontWeight: 500,
                ...labelSx,
              }}>
              {label}
            </Typography>
          ) : (
            label
          )}
        </Grid>
        {qty && (
          <Grid
            item
            xs={3}
            sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
            <Typography
              sx={{
                color: Colors.fontColorPrimary,
                fontWeight: 500,
                ...qtySx,
              }}>
              {refreshing ? '' : qty}
            </Typography>
          </Grid>
        )}
        <Grid
          item
          xs={qty ? 3 : 6}
          sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Typography
            sx={{
              color: Colors.black,
              fontWeight: 600,
              mb: 1,
              ...valueSx,
            }}>
            {refreshing ? '' : value}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RowInfo;
