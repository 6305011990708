import Box from '@mui/material/Box';

import AppFilterChip from '../../../components/AppFilterChip';
import { FILTER_TYPE } from '../../../components/AppTable/types/table.types';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectPayments } from '../../../redux/selectors/paymentsSelectors';
import { setActiveTab } from '../redux/paymentsSlice';
import { TabItem } from '../types/payments.types';

const PaymentsHeader = () => {
  const { activeTab } = useAppSelector(selectPayments);

  const dispatch = useAppDispatch();

  const tabs: TabItem[] = [
    {
      label: 'Succeeded',
      value: 'success',
    },
    {
      label: 'Refunded',
      value: 'refunded',
    },
    {
      label: 'Failed',
      value: 'failed',
    },
  ];

  const handleTabClick = (value: string[]) => {
    dispatch(setActiveTab(value));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        minWidth: '100%',
        alignItems: 'center',
        height: '77px',
        mt: -3,
      }}>
      <AppFilterChip
        chipHeader="Filter by Status"
        title={'Status'}
        data={tabs}
        value={activeTab}
        onSelect={handleTabClick}
        type={FILTER_TYPE.CHECKBOX}
      />
    </Box>
  );
};

export default PaymentsHeader;
