import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import arrowBack from '../../../assets/svgs/arrow_back.svg';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectDiscounts } from '../../../redux/selectors/discountsSelectors';
import { resetEditingData } from '../redux/discountsSlice';

const EditDiscountPageLayoutTitle = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { editingData } = useAppSelector(selectDiscounts);

  const handleBackClick = () => {
    dispatch(resetEditingData());
    navigate('/discounts/all-discounts');
  };

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={handleBackClick}>
        <img src={arrowBack} alt="back button icon" />
      </Box>
      <Typography variant={'h4'} sx={{ my: 2 }}>
        {editingData?.name ?? 'Edit Discount'}
      </Typography>
    </Box>
  );
};

export default EditDiscountPageLayoutTitle;
