import { Navigate } from 'react-router';
import { useAppSelector } from '../hooks';
import { selectAuth } from '../redux/selectors/authSelectors';
import {
  ADYEN_LEGAL_ENTITY_STATUS,
  RESTAURANT_PAYMENT_PROVIDER,
} from '../constants';

/**
 * DefaultNavigation Component
 *
 * Handles default routing based on Adyen account status:
 * - For fully onboarded accounts (SUCCESS, ACTION_REQUIRED, SUCCESS_REVIEW_PENDING):
 *   Redirects to transactions if POS and paymentsOnly are enabled
 * - For pending accounts (REVIEW_PENDING, PENDING):
 *   Always redirects to overview page to show onboarding status
 * - For non-Adyen providers:
 *   Redirects based on provider-specific rules
 */
const DefaultNavigation = () => {
  const { restaurant } = useAppSelector(selectAuth);

  const isAdyenPaymentProvider =
    restaurant?.paymentProvider === RESTAURANT_PAYMENT_PROVIDER.ADYEN;

  if (isAdyenPaymentProvider) {
    const adyenStatus = restaurant?.adyenAccount?.status;

    // For successfully onboarded accounts (including those with pending capabilities)
    if (
      (adyenStatus === ADYEN_LEGAL_ENTITY_STATUS.SUCCESS ||
        adyenStatus === ADYEN_LEGAL_ENTITY_STATUS.ACTION_REQUIRED ||
        adyenStatus === ADYEN_LEGAL_ENTITY_STATUS.SUCCESS_REVIEW_PENDING) &&
      restaurant?.isPos &&
      restaurant?.paymentsOnly
    ) {
      return <Navigate replace to="transactions" />;
    }

    // For accounts still in onboarding process
    if (
      adyenStatus === ADYEN_LEGAL_ENTITY_STATUS.REVIEW_PENDING ||
      adyenStatus === ADYEN_LEGAL_ENTITY_STATUS.PENDING
    ) {
      return <Navigate replace to="overview" />;
    }

    // Default to overview for Adyen users
    return <Navigate replace to="overview" />;
  } else if (
    restaurant?.paymentProvider === RESTAURANT_PAYMENT_PROVIDER.STRIPE
  ) {
    return <Navigate replace to="payments" />;
  }

  return null;
};

export default DefaultNavigation;
