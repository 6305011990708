import { useLocation } from 'react-router';

import AppVerticalTabs, {
  IVerticalTabItem,
} from '../../../components/AppVerticalTabs';
import { useTabNavigation } from '../../../hooks';
import { DISCOUNT_STEP_ID } from '../constants';

const CreateEditDiscountsTabs = () => {
  const location = useLocation();

  const CREATE_EDIT_DISCOUNT_TABS: IVerticalTabItem[] = [
    {
      id: DISCOUNT_STEP_ID.DISCOUNT_DETAILS,
      label: 'Discount Details',
      path: `${location.pathname}#discount-details`,
    },
    {
      id: DISCOUNT_STEP_ID.APPLIED_TO,
      label: 'Applied to',
      path: `${location.pathname}#applied-to`,
    },
    {
      id: DISCOUNT_STEP_ID.AVAILABILITY,
      label: 'Availability',
      path: `${location.pathname}#availability`,
    },
    {
      id: DISCOUNT_STEP_ID.ADVANCED,
      label: 'Advanced',
      path: `${location.pathname}#advanced`,
    },
  ];

  const { currentTab, handleTabChange } = useTabNavigation(
    CREATE_EDIT_DISCOUNT_TABS,
    DISCOUNT_STEP_ID.DISCOUNT_DETAILS,
  );

  return (
    <AppVerticalTabs
      tabs={CREATE_EDIT_DISCOUNT_TABS}
      currentTab={currentTab}
      handleTabChange={handleTabChange}
    />
  );
};

export default CreateEditDiscountsTabs;
