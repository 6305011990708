import AppVerticalTabs from '../../../components/AppVerticalTabs';
import { useAppDispatch, useTabNavigation } from '../../../hooks';
import { resetMenuPaginationState } from '../../menu/redux/menuPaginationSlice';
import {
  SHIFT_REPORT_NAVIGATION_TABS,
  SHIFT_REPORT_TABS,
} from '../constants/report.tabs.contants';

function ShiftTabs() {
  const dispatch = useAppDispatch();
  const { currentTab, handleTabChange } = useTabNavigation(
    SHIFT_REPORT_TABS,
    SHIFT_REPORT_NAVIGATION_TABS.DETAILED,
  );

  return (
    <AppVerticalTabs
      tabs={SHIFT_REPORT_TABS}
      currentTab={currentTab}
      horizontal
      handleTabChange={(tab) => {
        dispatch(resetMenuPaginationState());
        handleTabChange(tab);
      }}
    />
  );
}

export default ShiftTabs;
