import { CARD_TYPES } from '../../../constants';
import { BasePaginationExtras } from '../../../types/api.types';

export interface IShiftSummaryResponseDTO {
  shiftId: number;
  shiftNumber: string;
  createdAt: string;
  openedByName: string;
  closedByName: string;
  totalGross: number;
  totalRefundAmount: number;
  netTotal: number;
  totalDiscount: number;
}

export interface ShiftSummaryParams {
  date_from?: string;
  date_to?: string;
  limit?: number;
  skip?: number;
  offset?: string;
}

export interface IShiftSummaryStatusResponseDTO {
  showLoadingScreen: boolean;
}

export interface IShiftSummeryResponseDTO {
  ShiftSummaryData: string;
}

export interface ShiftSummaryExtras extends BasePaginationExtras {
  lastUpdated?: Date;
}

export type ShiftSummaryState = {
  shiftsReportStatus: IShiftSummaryStatusResponseDTO;
  ShiftSummaryData: IShiftSummaryResponseDTO[];
  extras: ShiftSummaryExtras;
  refreshing: boolean;
  singleShiftSummaryData?: ISingleShiftSummaryResponseDTO;
  consolidatedShiftSummaryData?: IConsolidatedShiftSummaryResponseDTO;
};

export interface IShiftSummaryTable extends Record<string, unknown> {
  shiftNumber: string;
  createdAt: string;
  openedByName: string;
  closedByName: string;
  totalGross: number;
  totalRefundAmount: number;
  netTotal: number;
}

export interface ISingleShift {
  id: number;
  createdAt: Date;
  updatedAt: string;
  closedAt: Date;
  status: string;
  shiftNumber: string;
  restaurant: number;
  cashierId: string;
  openedById: number;
  openedByName: string;
  openingNote: string;
  closedById: number;
  closedByName: string;
  closingNote: string;
  openingBalance: number;
  closingBalance: number;
}

interface ICardSummary {
  paymentMethod: string;
  cardBrand: string;
  count: number;
  amount: number;
}

export interface ICustomPaymentSummary {
  paymentMethodType: string;
  paymentMethodId: string;
  paymentMethodName: string;
  sequenceNumber: number;
  count: number;
  amount: number;
  amountGiven: number;
}

interface ICashPaymentSummary {
  paymentMethodType: 'cash';
  paymentMethodName: string;
  paymentMethodId: string;
  count: number;
  amount: number;
}

export interface IPaymentSummary {
  payments: {
    totalAmount: number;
    totalCount: number;
    cards: {
      totalAmount: number;
      totalCount: number;
      data: ICardSummary[];
    };
    custom: {
      totalAmount: number;
      totalCount: number;
      data: ICustomPaymentSummary[];
    };
    cash: ICashPaymentSummary;
    wallets: {
      totalAmount: number;
      totalCount: number;
      data: ICustomPaymentSummary;
    };
  };
  refunds: {
    totalAmount: number;
    totalCount: number;
    cards: {
      totalAmount: number;
      totalCount: number;
      data: ICardSummary[];
    };
    custom: {
      totalAmount: number;
      totalCount: number;
      data: ICustomPaymentSummary[];
    };
    cash: ICashPaymentSummary;
    wallets: {
      totalAmount: number;
      totalCount: number;
      data: ICustomPaymentSummary;
    };
  };
  netPayments: {
    totalProcessedAmount: number;
    totalProcessedCount: number;
    totalRefundedAmount: number;
    totalRefundedCount: number;
    totalNetAmount: number;
    cards: {
      totalProcessedAmount: number;
      totalProcessedCount: number;
      totalRefundedAmount: number;
      totalRefundedCount: number;
      totalNetAmount: number;
      data: INetPaymentCardSummary[];
    };
    cash: {
      paymentMethodType: string;
      paymentMethodName: string;
      paymentMethodId: string;
      sequenceNumber: number;
      processedAmount: number;
      processedCount: number;
      refundedAmount: number;
      refundedCount: number;
      netAmount: number;
    };
    custom: {
      totalProcessedAmount: number;
      totalProcessedCount: number;
      totalRefundedAmount: number;
      totalRefundedCount: number;
      totalNetAmount: number;
      data: INetPaymentCustomSummary[];
    };
    wallets: {
      totalProcessedAmount: number;
      totalProcessedCount: number;
      totalRefundedAmount: number;
      totalRefundedCount: number;
      totalNetAmount: number;
      data: INetPaymentCustomSummary[];
    };
  };
  cashDrawer: {
    openingBalance: number;
    cashIn: number;
    cashOut: number;
    cashTransactions: number;
    cashRefunds: number;
    expectedBalance: number;
    closingBalance: number;
  };
}

export interface IDiscountSummary {
  totalAmount: number;
  totalCount: number;
  itemDiscounts: {
    totalAmount: number;
    totalCount: number;
    data: IDiscountDetail[];
  };
  orderDiscounts: {
    totalAmount: number;
    totalCount: number;
    data: IDiscountDetail[];
  };
}

export interface IDiscountDetail {
  id: number;
  name: string;
  count: number;
  amount: number;
}

export interface IServiceChargeSummary {
  totalAmount: number;
  totalRefundAmount: number;
}

export interface IGstSummary {
  totalAmount: number;
  totalRefundAmount: number;
}

export interface IVoidOrderSummary {
  totalAmount: number;
  totalCount: number;
}

export interface ICompletedOrderSummary {
  totalAmount: number;
  totalCount: number;
}

export interface ICategoryDetails {
  categoryRefId: string;
  categoryName: string;
  noOfSales: number;
  grossTotal: number;
  discounts: number;
  gst: number;
  serviceCharge: number;
  refunds: number;
  refundedGST: number;
  refundedServiceCharge: number;
}
export interface ICategoryDetailsSummary {
  hideCategoryComponent: boolean;
  totalCount: number;
  totalGross: number;
  totalRefunds: number;
  totalGST: number;
  totalServiceCharge: number;
  totalDiscounts: number;
  totalRefundedGST: number;
  totalRefundedServiceCharge: number;
  data: ICategoryDetails[];
  custom: {
    categoryRefId: string;
    categoryName: string;
    noOfSales: number;
    grossTotal: number;
    discounts: number;
    gst: number;
    serviceCharge: number;
    refunds: number;
    refundedGST: number;
    refundedServiceCharge: number;
  } | null;
}

export interface IOrderSummary {
  discounts: IDiscountSummary;
  serviceCharges: IServiceChargeSummary;
  custom: ICustomSummary;
  gst: IGstSummary;
  voidOrders: IVoidOrderSummary;
  voidItems: IVoidOrderSummary;
  completedOrders: ICompletedOrderSummary;
  categoryDetails: ICategoryDetailsSummary;
}

export interface ICustomSummary {
  totalAmount: number;
  totalRefundAmount: number;
}

export interface ISingleShiftSummaryResponseDTO {
  shift: ISingleShift;
  orderSummary: IOrderSummary;
  paymentsSummary: IPaymentSummary;
}

export interface IConsolidatedShiftSummaryResponseDTO {
  shiftCount: IShiftCountSummary;
  orderSummary: IOrderSummary;
  paymentsSummary: IPaymentSummary;
}

interface INetPaymentCardSummary {
  paymentMethod: string;
  cardBrand: CARD_TYPES;
  processedCount: number;
  processedAmount: number;
  refundedCount: number;
  refundedAmount: number;
  netAmount: number;
}

interface INetPaymentCustomSummary {
  paymentMethodType: string;
  paymentMethodName: string;
  paymentMethodId: string;
  sequenceNumber: number;
  processedAmount: number;
  processedCount: number;
  refundedAmount: number;
  refundedCount: number;
  netAmount: number;
}

interface IShiftCountSummary {
  opened: number;
  closed: number;
  ongoing: number;
}

export enum ExportReportType {
  singleShift = 'singleShift',
  consolidatedShift = 'consolidatedShift',
}
