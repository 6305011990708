import { Navigate, Outlet } from 'react-router-dom';

import { useAppSelector } from '../hooks';
import { selectAuth } from '../redux/selectors/authSelectors';

const RequireAuth = () => {
  const { token } = useAppSelector(selectAuth);

  return token ? <Outlet /> : <Navigate to="login" replace />;
};

export default RequireAuth;
