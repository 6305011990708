import AppVerticalTabs from '../../../components/AppVerticalTabs';
import { useTabNavigation } from '../../../hooks';
import { DISCOUNT_NAVIGATION_TABS, DISCOUNT_TABS } from '../constants';

const DiscountsTabs = () => {
  const { currentTab, handleTabChange } = useTabNavigation(
    DISCOUNT_TABS,
    DISCOUNT_NAVIGATION_TABS.DISCOUNTS,
  );

  return (
    <AppVerticalTabs
      tabs={DISCOUNT_TABS}
      currentTab={currentTab}
      handleTabChange={(tab) => {
        handleTabChange(tab);
      }}
    />
  );
};

export default DiscountsTabs;
