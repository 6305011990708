import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import IconButton from '@mui/material/IconButton';
import { useEffect, useState } from 'react';

import { Colors } from '../theme/colors';

interface ScrollToTopProps {
  scrollContainerRef: React.RefObject<HTMLDivElement>;
}

const AppScrollToTopButton = ({ scrollContainerRef }: ScrollToTopProps) => {
  const [showScrollButton, setShowScrollButton] = useState(false);

  const handleScrollToTop = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const handleScrollButtonVisibility = () => {
      if (scrollContainerRef.current) {
        setShowScrollButton(scrollContainerRef.current.scrollTop > 300);
      }
    };

    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScrollButtonVisibility);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScrollButtonVisibility);
      }
    };
  }, [scrollContainerRef]);

  if (!showScrollButton) return null;

  return (
    <IconButton
      onClick={handleScrollToTop}
      sx={{
        position: 'fixed',
        bottom: '10%',
        left: '92%',
        backgroundColor: Colors.backgroundLight,
        color: Colors.black,
        zIndex: 1000,
      }}>
      <ArrowUpwardIcon />
    </IconButton>
  );
};

export default AppScrollToTopButton;
