import Container from '@mui/material/Container';
import { Outlet, useLocation } from 'react-router-dom';
import { useRef } from 'react';
import AppHeader from '../components/AppHeader';
import AppNavbar from '../components/AppNavbar';
import AppScrollToTopButton from '../components/AppScrollToTopButton';
import Box from '@mui/material/Box';

const InAppLayout = () => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const showScrollToTopButton =
    location.pathname.startsWith('/reports/shifts-summary/detailed') ||
    location.pathname === '/reports/shifts-summary/consolidated-shifts-summary';

  return (
    <Box
      ref={scrollContainerRef}
      sx={{ overflowY: 'auto', height: '100vh', position: 'relative' }}>
      <AppHeader />
      <AppNavbar scrollContainerRef={scrollContainerRef} />
      <Container maxWidth="xl">
        <Outlet />
      </Container>
      {showScrollToTopButton && (
        <AppScrollToTopButton scrollContainerRef={scrollContainerRef} />
      )}
    </Box>
  );
};

export default InAppLayout;
