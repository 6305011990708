import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import loginLogo from '../assets/loginLogo.png';
import AppAuthFooter from '../components/AppAuthFooter';
import { Colors } from '../theme/colors';

const NotFoundPage = () => {
  return (
    <Box
      sx={{
        background: `linear-gradient(172deg, ${Colors.backgroundLogin} 50%, ${Colors.backgroundPrimary} 50%)`,
      }}>
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          height: '100vh',
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '500px',
          }}>
          <Box sx={{ mb: 2 }}>
            <img src={loginLogo} alt="Logo" />
          </Box>

          <Paper
            sx={{
              borderRadius: '28px',
              display: 'flex',
              flexDirection: 'column',
              p: 5,
              pb: 8,
              boxShadow: `0px 15px 35px 0px ${Colors.shadowPrimary}`,
            }}>
            <Typography
              variant="h5"
              sx={{
                textAlign: 'center',
                fontSize: '6rem',
                color: Colors.primary,
              }}>
              404
            </Typography>
            <Typography variant="h5" sx={{ textAlign: 'center' }}>
              Not Found
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'center', mt: 2 }}>
              We're sorry. The page you requested cannot be found.
            </Typography>
          </Paper>
          <AppAuthFooter />
        </Box>
      </Container>
    </Box>
  );
};

export default NotFoundPage;
