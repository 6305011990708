import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AuthLayout from '../../../hocs/AuthLayout';
import { Colors } from '../../../theme/colors';

const ArchivedRestaurantPage = () => {
  return (
    <AuthLayout>
      <Box sx={{ textAlign: 'center', mb: 3 }}>
        <Typography variant="h5" sx={{ mb: 2, color: Colors.textPrimary }}>
          Restaurant Not Accessible
        </Typography>
        <Typography variant="body1" sx={{ color: Colors.fontColorSecondary }}>
          This restaurant is no longer accessible. Please log out or switch to a
          different restaurant.
        </Typography>
      </Box>
    </AuthLayout>
  );
};

export default ArchivedRestaurantPage;
