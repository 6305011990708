import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import { useMemo } from 'react';

import AppDateTimePicker from '../../../components/AppDateTimeRangePicker';
import AppExportButton from '../../../components/AppExportButton';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { setReportDates } from '../../../redux/AppSlice';
import { selectApp } from '../../../redux/selectors/appSelectors';
import { selectPayments } from '../../../redux/selectors/paymentsSelectors';
import { PaginatedResponseDTO } from '../../../types/api.types';
import { TABLE_TYPE } from '../constants';
import { fetchPaymentsExport } from '../redux/paymentsSlice';
import { IPaymentResponseDTO, IPaymentTable } from '../types/payments.types';
import { getFormattedPaymentExport } from '../utils/formatting.utils';

const PaymentsSideView = () => {
  const dispatch = useAppDispatch();
  const { columns, activeTab, refreshing } = useAppSelector(selectPayments);

  const { reportStartDate, reportEndDate } = useAppSelector(selectApp);

  const debouncedFetchExportData = useMemo(
    () =>
      debounce(
        async () => {
          const activeTabString = activeTab.join(',');
          const response = await dispatch(
            fetchPaymentsExport({
              type: TABLE_TYPE.EXPORT,
              status: activeTabString,
              date_from: reportStartDate,
              date_to: reportEndDate,
            }),
          );

          let results: IPaymentResponseDTO[] = [];
          if (response.meta.requestStatus === 'fulfilled' && response.payload) {
            const payload = response.payload as PaginatedResponseDTO<
              IPaymentResponseDTO,
              never
            >;
            results = payload.results;
          }

          return results;
        },
        500,
        { leading: true, trailing: false },
      ),
    [activeTab, dispatch, reportStartDate, reportEndDate],
  );

  const onCalendarSave = (startDate: string | null, endDate: string | null) => {
    dispatch(
      setReportDates({
        reportStartDate: startDate ?? undefined,
        reportEndDate: endDate ?? undefined,
      }),
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        mb: 2,
      }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', p: 1.2 }}>
        <AppDateTimePicker
          defaultValues={[dayjs.tz(reportStartDate), dayjs.tz(reportEndDate)]}
          disabled={refreshing}
          onSave={onCalendarSave}
        />
        <Box sx={{ ml: 1.2 }} />
        <AppExportButton<IPaymentResponseDTO, IPaymentTable>
          fileName={`payments`}
          columns={columns}
          dataFetcher={debouncedFetchExportData}
          formatter={getFormattedPaymentExport}
          buttonSx={{ height: 30, fontSize: '12px' }}
          iconSx={{ width: 14 }}
        />
      </Box>
    </Box>
  );
};

export default PaymentsSideView;
