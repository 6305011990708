import { Navigate, Outlet } from 'react-router-dom';

import { useAppSelector } from '../hooks';
import { selectAuth } from '../redux/selectors/authSelectors';

const RequirePos = () => {
  const { restaurant } = useAppSelector(selectAuth);

  return restaurant?.isPos ? <Outlet /> : <Navigate to="/" replace />;
};

export default RequirePos;
