import debounce from 'lodash/debounce';
import { useEffect, useMemo, useState } from 'react';

import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { selectApp } from '../../../redux/selectors/appSelectors';
import { selectShiftSummary } from '../../../redux/selectors/shiftSummarySelectors';
import { getConsolidatedShiftSummaryExport } from '../redux/shiftSummarySlice';
import {
  ExportReportType,
  IConsolidatedShiftSummaryResponseDTO,
} from '../types/shift-summary.types';
import ShiftSummaryExportButton from './ShiftSummaryExportButton';

const ConsolidatedShiftsSummaryExport = () => {
  const dispatch = useAppDispatch();

  const { reportStartDate, reportEndDate } = useAppSelector(selectApp);

  const { refreshing } = useAppSelector(selectShiftSummary);
  const [exportData, setExportData] =
    useState<IConsolidatedShiftSummaryResponseDTO | null>(null);
  const [isButtonLoading, setIsButtonLoading] = useState(true);

  const debouncedFetchExportData = useMemo(
    () =>
      debounce(async () => {
        setIsButtonLoading(true);
        const response = await dispatch(
          getConsolidatedShiftSummaryExport({
            date_from: reportStartDate,
            date_to: reportEndDate,
          }),
        );

        if (typeof response.payload !== 'string') {
          setExportData(
            response.payload as IConsolidatedShiftSummaryResponseDTO,
          );
        }
        setIsButtonLoading(false);
        return null;
      }, 500),
    [dispatch, reportStartDate, reportEndDate],
  );

  useEffect(() => {
    debouncedFetchExportData();
  }, [debouncedFetchExportData]);

  return (
    <ShiftSummaryExportButton<IConsolidatedShiftSummaryResponseDTO>
      data={exportData}
      reportStartDate={reportStartDate}
      reportEndDate={reportEndDate}
      fileName={'consolidatedshiftsummary'}
      disabled={refreshing || isButtonLoading}
      buttonSx={{ height: 30, fontSize: '12px' }}
      iconSx={{ width: 14 }}
      report={ExportReportType.consolidatedShift}
    />
  );
};

export default ConsolidatedShiftsSummaryExport;
