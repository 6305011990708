import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { NavLink } from 'react-router-dom';
import { RefObject, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../hooks';
import { selectAuth } from '../redux/selectors/authSelectors';
import { Colors } from '../theme/colors';
import { RESTAURANT_PAYMENT_PROVIDER } from '../constants';
import { ADYEN_LEGAL_ENTITY_STATUS } from '../constants/ApiConstants';

interface AppNavbarProps {
  scrollContainerRef: RefObject<HTMLDivElement>;
}

/**
 * AppNavbar Component
 *
 * Manages navigation items based on Adyen account status:
 * - For accounts in onboarding (PENDING, REVIEW_PENDING):
 *   Shows overview to track onboarding progress
 * - For onboarded accounts (SUCCESS, ACTION_REQUIRED, SUCCESS_REVIEW_PENDING):
 *   Shows transactions and payouts for full payment functionality
 * - Settings tab is always visible
 *
 * Non-Adyen providers have their own navigation logic
 */
const AppNavbar = ({ scrollContainerRef }: AppNavbarProps) => {
  const { restaurant } = useAppSelector(selectAuth);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollContainerRef.current) {
        setIsScrolled(scrollContainerRef.current.scrollTop > 0);
      }
    };

    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollContainerRef]);

  const navbarItems = useMemo(() => {
    const adyenStatus = restaurant?.adyenAccount?.status;
    const isAdyenProvider =
      restaurant?.paymentProvider === RESTAURANT_PAYMENT_PROVIDER.ADYEN;

    // Show overview for accounts still in onboarding
    const showOverview =
      isAdyenProvider &&
      (adyenStatus === ADYEN_LEGAL_ENTITY_STATUS.PENDING ||
        adyenStatus === ADYEN_LEGAL_ENTITY_STATUS.REVIEW_PENDING);

    // Show full payment functionality for onboarded accounts
    const isPayoutsAndTransactionsAvailable =
      isAdyenProvider &&
      (adyenStatus === ADYEN_LEGAL_ENTITY_STATUS.SUCCESS ||
        adyenStatus === ADYEN_LEGAL_ENTITY_STATUS.ACTION_REQUIRED ||
        adyenStatus === ADYEN_LEGAL_ENTITY_STATUS.SUCCESS_REVIEW_PENDING);

    if (isAdyenProvider) {
      return [
        ...(showOverview ? [{ route: 'overview', name: 'Overview' }] : []),
        ...(isPayoutsAndTransactionsAvailable
          ? [
              { route: 'transactions', name: 'Transactions' },
              { route: 'payouts', name: 'Payouts' },
              { route: 'reports', name: 'Reports' },
            ]
          : []),
        { route: 'settings', name: 'Settings' },
      ];
    } else {
      const items = [
        { route: 'payments', name: 'Payments' },
        { route: 'reports', name: 'Reports' },
      ];

      if (restaurant?.isPos) {
        items.push(
          { route: 'employees', name: 'Employees' },
          { route: 'menu', name: 'Menu' },
          { route: 'discounts', name: 'Discounts' },
          { route: 'settings', name: 'Settings' },
        );
      }

      return items;
    }
  }, [
    restaurant?.paymentProvider,
    restaurant?.adyenAccount?.status,
    restaurant?.isPos,
  ]);

  return (
    <Box
      sx={{
        height: '50px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        textDecoration: 'none',
        backgroundColor: Colors.backgroundPrimary,
        borderBottom: 1,
        borderColor: Colors.borderPrimary,
        boxShadow: isScrolled ? `0px 4px 10px ${Colors.shadowPrimary}` : 'none',
        position: 'sticky',
        top: '45px',
        zIndex: 999,
        width: '100%',
      }}
      data-testid="navbar">
      <Container maxWidth="xl">
        <nav
          style={{
            display: 'flex',
          }}>
          {navbarItems.map((el) => (
            <NavLink
              key={el.name}
              to={el.route}
              style={({ isActive }) => ({
                paddingTop: '1px',
                paddingBottom: '1px',
                paddingLeft: '10px',
                paddingRight: '10px',
                textDecoration: 'none',
                borderRadius: '50px',
                color: isActive ? Colors.white : Colors.black,
                background: isActive ? Colors.primary : '',
              })}>
              <Typography variant="body1" fontWeight={500}>
                {el.name}
              </Typography>
            </NavLink>
          ))}
        </nav>
      </Container>
    </Box>
  );
};

export default AppNavbar;
