import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import YupPassword from 'yup-password';

import AppButton from '../../../components/AppButton';
import AppTextInput from '../../../components/AppTextInput';
import AuthLayout from '../../../hocs/AuthLayout';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectAuth } from '../../../redux/selectors/authSelectors';
import { resetPassword } from '../redux/authSlice';
import GoBack from './Components/GoBack';
import mixpanel from 'mixpanel-browser';

YupPassword(yup);

interface IResetPasswordForm {
  newPassword: string;
  newPasswordConfirm: string;
}

const validationSchema: yup.ObjectSchema<IResetPasswordForm> = yup.object({
  newPassword: yup
    .string()
    .password()
    .test('password-required', 'Password is required', function (value) {
      return !!value && value.trim().length > 0;
    })
    .min(8, 'Password is too short')
    .minLowercase(1, 'Password must contain at least 1 lower case letter')
    .minUppercase(1, 'Password must contain at least 1 upper case letter')
    .minNumbers(1, 'Password must contain at least 1 number')
    .minSymbols(1, 'Password must contain at least 1 special character')
    .required('Password is required'),
  newPasswordConfirm: yup
    .string()
    .required('Confirm password is required')
    .oneOf([yup.ref('newPassword'), ''], 'Passwords must match'),
});

const ResetPasswordPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isLoadingResetPassword, passwordResetEmail, otpToken } =
    useAppSelector(selectAuth);

  useMemo(() => {
    mixpanel.track('Page View - Reset Password', {
      page: 'Reset Password Page',
    });
  }, []);

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<IResetPasswordForm>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      newPassword: '',
      newPasswordConfirm: '',
    },
  });

  const onsubmit = handleSubmit(async (values: IResetPasswordForm) => {
    const response = await dispatch(
      resetPassword({
        email: passwordResetEmail ?? '',
        token: otpToken ?? '',
        newPassword: values.newPassword,
      }),
    );

    if (response.meta.requestStatus === 'fulfilled') {
      navigate('/reset-password-success');
    }
  });

  return (
    <AuthLayout>
      <Typography variant="h5" sx={{ mb: 4 }}>
        Reset Password
      </Typography>

      <Box
        sx={{
          position: 'relative',
        }}>
        <Controller
          name="newPassword"
          control={control}
          render={({ field, fieldState: { invalid } }) => (
            <AppTextInput
              label="New Password"
              placeholder="new password"
              type={'password'}
              {...field}
              error={errors.newPassword?.message}
              touched={invalid}
              data-testid="password"
              iconPosition="end"
            />
          )}
        />

        <Controller
          name="newPasswordConfirm"
          control={control}
          render={({ field, fieldState: { invalid } }) => (
            <AppTextInput
              label="Confirm New Password"
              placeholder="confirm new password"
              type={'password'}
              {...field}
              error={errors.newPasswordConfirm?.message}
              touched={invalid}
              data-testid="password"
              iconPosition="end"
            />
          )}
        />
      </Box>

      <AppButton
        variant="contained"
        title="Continue"
        disabled={isSubmitting}
        loading={isLoadingResetPassword}
        onClick={() => {
          onsubmit();
        }}
        sx={{
          mt: 1,
          mb: 4,
        }}
      />
      <GoBack text={'Go back to sign in'} path={'/login'} />
    </AuthLayout>
  );
};

export default ResetPasswordPage;
