import Environment from '../config/environment';

// Utility to handle route prefetching
export const prefetchRoute = (importFn: () => Promise<unknown>) => {
  try {
    importFn();
  } catch (err) {
    if (Environment.isDebug) {
      console.error(err);
    }
  }
};

// Prefetch groups of routes
export const prefetchAuthRoutes = () => {
  prefetchRoute(() => import('../features/auth/pages/RegisterPage'));
  prefetchRoute(() => import('../features/auth/pages/ForgotPasswordPage'));
  prefetchRoute(() => import('../features/auth/pages/OTPVerificationPage'));
};

export const prefetchMenuRoutes = () => {
  prefetchRoute(() => import('../features/menu/pages/DishesPage'));
  prefetchRoute(() => import('../features/menu/pages/CategoriesPage'));
  prefetchRoute(() => import('../features/menu/pages/ChoicesPage'));
  prefetchRoute(() => import('../features/menu/pages/AddonsPage'));
};

export const prefetchSettingsRoutes = () => {
  prefetchRoute(() => import('../features/settings/pages/POSSettingsPage'));
  prefetchRoute(() => import('../features/settings/pages/DevicesPage'));
  prefetchRoute(
    () => import('../features/settings/pages/TicketConfigurationPage'),
  );
  prefetchRoute(() => import('../features/settings/pages/PaymentTypePage'));
  prefetchRoute(() => import('../features/settings/pages/AccountPage'));
  prefetchRoute(() => import('../features/settings/pages/ReceiptsPage'));
  prefetchRoute(() => import('../features/settings/pages/TaxesPage'));
  prefetchRoute(() => import('../features/settings/pages/RestaurantAreasPage'));
  prefetchRoute(() => import('../features/settings/pages/NotificationsPage'));
  prefetchRoute(() => import('../features/settings/pages/OrderTypePage'));
  prefetchRoute(
    () => import('../features/settings/pages/TransactionChargesPage'),
  );
};
