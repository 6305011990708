import Box from '@mui/material/Box';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LicenseInfo } from '@mui/x-license';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { lazy, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import AppCircularLoader from './components/AppCircularLoader';
import { ConfirmationPopupProvider } from './components/AppConfirmationPopup';
import AppLoader from './components/AppLoader';
import { SnackbarProvider } from './components/AppSnackbar';
import Environment from './config/environment';
import { initSentry } from './config/sentry-setup';
import ResetPasswordPage from './features/auth/pages/ResetPasswordPage';
import { getProfile, getRestaurant } from './features/auth/redux/authSlice';
import CreateDiscountPageLayoutTitle from './features/discounts/components/CreateDiscountPageLayoutTitle';
import CreateDiscountsStepper from './features/discounts/components/CreateDiscountsStepper';
import CreateEditDiscountsTabs from './features/discounts/components/CreateEditDiscountsTabs';
import CreateViewDiscountPageLayoutTitle from './features/discounts/components/CreateViewDiscountPageLayoutTitle';
import DiscountsHeader from './features/discounts/components/DiscountsHeader';
import DiscountsTabs from './features/discounts/components/DiscountsTabs';
import EditDiscountPageLayoutTitle from './features/discounts/components/EditDiscountPageLayoutTitle';
import CreateDiscountsPage from './features/discounts/pages/CreateDiscountsPage';
import EditDiscountsPage from './features/discounts/pages/EditDiscountsPage';
import MenuHeader from './features/menu/components/MenuHeader';
import MenuTabs from './features/menu/components/MenuTabs';
import PaymentsHeader from './features/payments/components/PaymentsHeader';
import PaymentsSideView from './features/payments/views/PaymentsSideView';
import PaymentReconciliationTabs from './features/reports/components/PaymentReconciliationTabs';
import ShiftTabs from './features/reports/components/ShiftTabs';
import ConsolidatedShiftSummaryPage from './features/reports/pages/ConsolidatedShiftSummaryPage';
import SettingsTabs from './features/settings/components/SettingsTabs';
import AppPageLayout from './hocs/AppPageLayout';
import InAppLayout from './hocs/InAppLayout';
import NoAuthAccess from './hocs/NoAuthAccess';
import OuterLayout from './hocs/OuterLayout';
import ReportsLayout from './hocs/ReportsLayout';
import RequireAuth from './hocs/RequireAuth';
import RequireNonPos from './hocs/RequireNonPos';
import RequirePOS from './hocs/RequirePOS';
import { useAppDispatch, useAppSelector } from './hooks';
import useWindowResize from './hooks/useWindowResize';
import NotFoundPage from './pages/NotFoundPage';
import DefaultNavigation from './hocs/DefaultNavigation';
import OverviewPage from './features/payments/pages/OverviewPage';
import PaymentsPage from './features/payments/pages/PaymentsPage';
import TransactionsPage from './features/payments/pages/TransactionsPage';
import RequireAdyenPayments from './hocs/RequireAdyenPayments';
import RequireStripePayments from './hocs/RequireStripePayments';
import WithAdyenAccountStatus from './hocs/WithAdyenLegalEntityStatus';
import { ADYEN_LEGAL_ENTITY_STATUS } from './constants';
import RequireSearchParamToken from './hocs/RequireSearchParamToken';
import {
  prefetchAuthRoutes,
  prefetchMenuRoutes,
  prefetchSettingsRoutes,
} from './utils/routePrefetch';
import { withSentryReactRouterV6Routing } from '@sentry/react';
import { selectAuth } from './redux/selectors/authSelectors';
import { fetchAccountWarnings } from './features/settings/redux/accountSlice';
import WithRestaurantStatus from './hocs/WithRestaurantStatus';
import ArchivedRestaurantPage from './features/auth/pages/ArchivedRestaurantPage';
import ArchivedRestaurantLayout from './hocs/ArchivedRestaurantLayout';

const LoginPage = lazy(() => import('./features/auth/pages/LoginPage'));

const MenuFeature = lazy(() => {
  const promise = import('./features/menu/pages/PagesPage');
  promise.then(() => prefetchMenuRoutes());
  return promise;
});

const SettingsFeature = lazy(() => {
  const promise = import('./features/settings/pages/GeneralSettingsPage');
  promise.then(() => prefetchSettingsRoutes());
  return promise;
});

const RegisterPage = lazy(() => {
  const promise = import('./features/auth/pages/RegisterPage');
  promise.then(() => prefetchAuthRoutes());
  return promise;
});

const PayoutsPage = lazy(() => import('./features/payouts/pages/PayoutsPage'));
const DeleteUserPage = lazy(() => import('./pages/DeleteUserPage'));

const InvitationPage = lazy(
  () => import('./features/auth/pages/InvitationPage'),
);
const InvalidTokenPage = lazy(
  () => import('./features/auth/pages/InvalidTokenPage'),
);
const RegisterSuccessPage = lazy(
  () => import('./features/auth/pages/RegisterSuccessPage'),
);
const ForgotPasswordPage = lazy(
  () => import('./features/auth/pages/ForgotPasswordPage'),
);
const OTPVerificationPage = lazy(
  () => import('./features/auth/pages/OTPVerificationPage'),
);
const PasswordResetSuccessPage = lazy(
  () => import('./features/auth/pages/PasswordResetSuccessPage'),
);

const DishesPage = lazy(() => import('./features/menu/pages/DishesPage'));
const CategoriesPage = lazy(
  () => import('./features/menu/pages/CategoriesPage'),
);
const ChoicesPage = lazy(() => import('./features/menu/pages/ChoicesPage'));
const AddonsPage = lazy(() => import('./features/menu/pages/AddonsPage'));
const SubCategoriesPage = lazy(
  () => import('./features/menu/pages/SubCategoriesPage'),
);
const CreatePagesPage = lazy(
  () => import('./features/menu/pages/CreatePagesPage'),
);
const EditPagesPage = lazy(() => import('./features/menu/pages/EditPagesPage'));
const CreateDishes = lazy(
  () => import('./features/menu/pages/CreateDishesPage'),
);
const EditDishes = lazy(() => import('./features/menu/pages/EditDishesPage'));
const CreateChoicesPage = lazy(
  () => import('./features/menu/pages/CreateChoicesPage'),
);
const EditChoicesPage = lazy(
  () => import('./features/menu/pages/EditChoicesPage'),
);
const CreateAddOnsPage = lazy(
  () => import('./features/menu/pages/CreateAddOnsPage'),
);
const EditAddOnsPage = lazy(
  () => import('./features/menu/pages/EditAddOnsPage'),
);

const POSSettingsPage = lazy(
  () => import('./features/settings/pages/POSSettingsPage'),
);
const OrderTypePage = lazy(
  () => import('./features/settings/pages/OrderTypePage'),
);
const AccountPage = lazy(() => import('./features/settings/pages/AccountPage'));
const PaymentTypePage = lazy(
  () => import('./features/settings/pages/PaymentTypePage'),
);
const TaxesPage = lazy(() => import('./features/settings/pages/TaxesPage'));
const TicketConfigurationPage = lazy(
  () => import('./features/settings/pages/TicketConfigurationPage'),
);
const ReceiptsPage = lazy(
  () => import('./features/settings/pages/ReceiptsPage'),
);
const RestaurantAreasPage = lazy(
  () => import('./features/settings/pages/RestaurantAreasPage'),
);
const TransactionChargesPage = lazy(
  () => import('./features/settings/pages/TransactionChargesPage'),
);
const DevicesPage = lazy(() => import('./features/settings/pages/DevicesPage'));
const CreateRestaurantAreasPage = lazy(
  () => import('./features/settings/pages/CreateRestaurantAreasPage'),
);
const EditRestaurantAreasPage = lazy(
  () => import('./features/settings/pages/EditRestaurantAreasPage'),
);
const NotificationsPage = lazy(
  () => import('./features/settings/pages/NotificationsPage'),
);

const ReportsHomePage = lazy(
  () => import('./features/reports/pages/ReportsHomePage'),
);

const ReconciliationSummary = lazy(
  () => import('./features/reports/pages/ReconciliationSummaryPage'),
);

const OrderSummaryPage = lazy(
  () => import('./features/reports/pages/OrderSummaryPage'),
);
const SalesByMenuSummaryPage = lazy(
  () => import('./features/reports/pages/SalesByMenuSummaryPage'),
);
const HourlySalesSummaryPage = lazy(
  () => import('./features/reports/pages/HourlySalesSummaryPage'),
);
const ReportsReceiptsPage = lazy(
  () => import('./features/reports/pages/ReceiptsPage'),
);
const ShiftsSummaryPage = lazy(
  () => import('./features/reports/pages/ShiftsSummaryPage'),
);
const SingleShiftSummaryPage = lazy(
  () => import('./features/reports/pages/SingleShiftSummaryPage'),
);
const SalesSummaryPage = lazy(
  () => import('./features/reports/pages/SalesSummaryPage'),
);
const EmployeesPage = lazy(
  () => import('./features/employee/pages/EmployeesPage'),
);
const DiscountsPage = lazy(
  () => import('./features/discounts/pages/DiscountsPage'),
);
//TODO: Hidden temporally const DiscountReasonsPage = lazy(
//   () => import('./features/discounts/pages/DiscountReasonsPage'),
// );
const DiscountDetailsPage = lazy(
  () => import('./features/discounts/pages/DiscountDetailsPage'),
);
const AppliedToPage = lazy(
  () => import('./features/discounts/pages/AppliedToPage'),
);
const AvailabilityPage = lazy(
  () => import('./features/discounts/pages/AvailabilityPage'),
);
const AdvancedPage = lazy(
  () => import('./features/discounts/pages/AdvancedPage'),
);

const DailySummaryPage = lazy(
  () => import('./features/reports/components/DailySummaryPage'),
);

initSentry();
const SentryRoutes = withSentryReactRouterV6Routing(Routes);

dayjs.extend(utc);
dayjs.extend(tz);
dayjs.tz.setDefault('Asia/Singapore');

// NOTE: This is a programmatically generated license key by Layan.
LicenseInfo.setLicenseKey(Environment.muiLicenseKey);

const App = () => {
  const dispatch = useAppDispatch();
  const zoom = useWindowResize();
  const { restaurant } = useAppSelector(selectAuth);

  useEffect(() => {
    dispatch(getProfile());
    dispatch(getRestaurant());
  }, [dispatch]);

  useEffect(() => {
    if (restaurant && restaurant.adyenAccountId) {
      dispatch(fetchAccountWarnings());
    }
  }, [dispatch, restaurant]);

  return (
    <SnackbarProvider>
      <ConfirmationPopupProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box
            sx={{
              width: `${(1 / zoom) * 100}%`,
              height: `${(1 / zoom) * 100}%`,
              transition: 'width 0.3s ease-in-out',
            }}>
            <SentryRoutes>
              <Route element={<OuterLayout />}>
                <Route element={<NoAuthAccess />}>
                  <Route
                    path="login"
                    element={
                      <Suspense fallback={<AppCircularLoader />}>
                        <LoginPage />
                      </Suspense>
                    }
                  />
                  <Route
                    path="forgot-password"
                    element={
                      <Suspense fallback={<AppCircularLoader />}>
                        <ForgotPasswordPage />
                      </Suspense>
                    }
                  />
                  <Route
                    path="otp-verification"
                    element={
                      <Suspense fallback={<AppCircularLoader />}>
                        <OTPVerificationPage />
                      </Suspense>
                    }
                  />
                  <Route
                    path="reset-password"
                    element={
                      <Suspense fallback={<AppCircularLoader />}>
                        <ResetPasswordPage />
                      </Suspense>
                    }
                  />
                  <Route
                    path="reset-password-success"
                    element={
                      <Suspense fallback={<AppCircularLoader />}>
                        <PasswordResetSuccessPage />
                      </Suspense>
                    }
                  />
                </Route>
                <Route element={<RequireSearchParamToken />}>
                  <Route
                    path="register"
                    element={
                      <Suspense fallback={<AppCircularLoader />}>
                        <RegisterPage />
                      </Suspense>
                    }
                  />
                  <Route
                    path="invite"
                    element={
                      <Suspense fallback={<AppCircularLoader />}>
                        <InvitationPage />
                      </Suspense>
                    }
                  />
                  <Route
                    path="invite/invalid"
                    element={
                      <Suspense fallback={<AppCircularLoader />}>
                        <InvalidTokenPage />
                      </Suspense>
                    }
                  />
                  <Route
                    path="register/success"
                    element={
                      <Suspense fallback={<AppCircularLoader />}>
                        <RegisterSuccessPage />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  path="/account-deletion"
                  element={
                    <Suspense fallback={<AppCircularLoader />}>
                      <DeleteUserPage />
                    </Suspense>
                  }
                />
              </Route>

              <Route element={<RequireAuth />}>
                <Route element={<WithRestaurantStatus active={false} />}>
                  <Route element={<ArchivedRestaurantLayout />}>
                    <Route
                      path="/restaurant-archived"
                      element={
                        <Suspense fallback={<AppCircularLoader />}>
                          <ArchivedRestaurantPage />
                        </Suspense>
                      }
                    />
                  </Route>
                </Route>
              </Route>

              <Route element={<InAppLayout />}>
                <Route element={<RequireAuth />}>
                  <Route element={<WithRestaurantStatus active={true} />}>
                    <Route path="" element={<DefaultNavigation />} />
                    <Route element={<RequireAdyenPayments />}>
                      <Route
                        element={
                          <WithAdyenAccountStatus
                            status={[
                              ADYEN_LEGAL_ENTITY_STATUS.PENDING,
                              ADYEN_LEGAL_ENTITY_STATUS.REVIEW_PENDING,
                            ]}
                            requirePosAndPaymentsOnly={false}
                          />
                        }>
                        <Route
                          path="overview"
                          element={
                            <Suspense fallback={<AppLoader />}>
                              <OverviewPage />
                            </Suspense>
                          }
                        />
                      </Route>
                      <Route
                        element={
                          <WithAdyenAccountStatus
                            status={[
                              ADYEN_LEGAL_ENTITY_STATUS.SUCCESS,
                              ADYEN_LEGAL_ENTITY_STATUS.ACTION_REQUIRED,
                              ADYEN_LEGAL_ENTITY_STATUS.SUCCESS_REVIEW_PENDING,
                            ]}
                          />
                        }>
                        <Route
                          path="payouts"
                          element={
                            <>
                              <AppPageLayout title="Payouts" />
                              <Suspense fallback={<AppLoader />}>
                                <PayoutsPage />
                              </Suspense>
                            </>
                          }
                        />
                        <Route
                          path="transactions"
                          element={
                            <>
                              <AppPageLayout title="Transactions" />
                              <Suspense fallback={<AppLoader />}>
                                <TransactionsPage />
                              </Suspense>
                            </>
                          }
                        />
                      </Route>
                    </Route>

                    <Route element={<RequireStripePayments />}>
                      <Route
                        path="payments"
                        element={
                          <>
                            <AppPageLayout
                              title="Payments"
                              HeaderComponent={PaymentsHeader}
                              SideComponent={PaymentsSideView}
                              divider={false}
                            />
                            <Suspense fallback={<AppLoader />}>
                              <PaymentsPage />
                            </Suspense>
                          </>
                        }
                      />
                    </Route>

                    <Route element={<RequirePOS />}>
                      <Route element={<RequireStripePayments />}>
                        <Route path="menu">
                          <Route
                            path=""
                            element={<Navigate replace to="pages" />}
                          />
                          <Route
                            element={
                              <AppPageLayout
                                title="Menu"
                                TabComponent={MenuTabs}
                                HeaderComponent={MenuHeader}
                                divider={true}
                              />
                            }>
                            <Route
                              path="pages"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <MenuFeature />
                                </Suspense>
                              }
                            />
                            <Route
                              path="items"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <DishesPage />
                                </Suspense>
                              }
                            />
                            <Route
                              path="categories"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <CategoriesPage />
                                </Suspense>
                              }
                            />
                            <Route
                              path="choices"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <ChoicesPage />
                                </Suspense>
                              }
                            />
                            <Route
                              path="addons"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <AddonsPage />
                                </Suspense>
                              }
                            />
                            <Route
                              path="subcategories"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <SubCategoriesPage />
                                </Suspense>
                              }
                            />
                          </Route>
                          <Route path="pages">
                            <Route
                              path="create"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <CreatePagesPage />
                                </Suspense>
                              }
                            />
                            <Route
                              path="edit/:id"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <EditPagesPage />
                                </Suspense>
                              }
                            />
                          </Route>
                          <Route path="items">
                            <Route
                              path="create"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <CreateDishes />
                                </Suspense>
                              }
                            />
                            <Route
                              path="edit/:id"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <EditDishes />
                                </Suspense>
                              }
                            />
                          </Route>
                          <Route path="choices">
                            <Route
                              path="create"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <CreateChoicesPage />
                                </Suspense>
                              }
                            />
                            <Route
                              path="edit/:id"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <EditChoicesPage />
                                </Suspense>
                              }
                            />
                          </Route>
                          <Route path="addons">
                            <Route
                              path="create"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <CreateAddOnsPage />
                                </Suspense>
                              }
                            />
                            <Route
                              path="edit/:id"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <EditAddOnsPage />
                                </Suspense>
                              }
                            />
                          </Route>
                        </Route>
                      </Route>

                      <Route path="settings">
                        <Route
                          path=""
                          element={<Navigate replace to="general" />}
                        />
                        <Route
                          element={
                            <AppPageLayout
                              title="Settings"
                              TabComponent={SettingsTabs}
                              divider={true}
                            />
                          }>
                          <Route
                            path="general"
                            element={
                              <Suspense fallback={<AppLoader />}>
                                <SettingsFeature />
                              </Suspense>
                            }
                          />

                          <Route element={<RequireAdyenPayments />}>
                            <Route
                              element={
                                <WithAdyenAccountStatus
                                  status={[
                                    ADYEN_LEGAL_ENTITY_STATUS.SUCCESS,
                                    ADYEN_LEGAL_ENTITY_STATUS.ACTION_REQUIRED,
                                    ADYEN_LEGAL_ENTITY_STATUS.SUCCESS_REVIEW_PENDING,
                                  ]}
                                />
                              }>
                              <Route
                                path="account"
                                element={
                                  <Suspense fallback={<AppLoader />}>
                                    <AccountPage />
                                  </Suspense>
                                }
                              />
                            </Route>
                          </Route>
                          <Route element={<RequireStripePayments />}>
                            <Route
                              path="pos-settings"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <POSSettingsPage />
                                </Suspense>
                              }
                            />
                            <Route
                              path="order-types"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <OrderTypePage />
                                </Suspense>
                              }
                            />
                          </Route>
                          <Route
                            path="payment-types"
                            element={
                              <Suspense fallback={<AppLoader />}>
                                <PaymentTypePage />
                              </Suspense>
                            }
                          />
                          <Route element={<RequireStripePayments />}>
                            <Route
                              path="notifications"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <NotificationsPage />
                                </Suspense>
                              }
                            />
                            <Route
                              path="taxes"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <TaxesPage />
                                </Suspense>
                              }
                            />
                            <Route
                              path="tickets"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <TicketConfigurationPage />
                                </Suspense>
                              }
                            />
                            <Route
                              path="receipts"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <ReceiptsPage />
                                </Suspense>
                              }
                            />
                            <Route
                              path="restaurant-areas"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <RestaurantAreasPage />
                                </Suspense>
                              }
                            />
                            <Route
                              path="charges"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <TransactionChargesPage />
                                </Suspense>
                              }
                            />
                          </Route>
                          <Route
                            path="devices"
                            element={
                              <Suspense fallback={<AppLoader />}>
                                <DevicesPage />
                              </Suspense>
                            }
                          />
                        </Route>
                        <Route element={<RequireStripePayments />}>
                          <Route path="restaurant-areas">
                            <Route
                              path="create"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <CreateRestaurantAreasPage />
                                </Suspense>
                              }
                            />
                            <Route
                              path="edit/:id"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <EditRestaurantAreasPage />
                                </Suspense>
                              }
                            />
                          </Route>
                        </Route>
                      </Route>

                      <Route element={<RequireStripePayments />}>
                        <Route
                          path="employees"
                          element={
                            <Suspense fallback={<AppLoader />}>
                              <>
                                <AppPageLayout
                                  title="Employees"
                                  divider={false}
                                />

                                <EmployeesPage />
                              </>
                            </Suspense>
                          }
                        />
                      </Route>

                      <Route element={<RequireStripePayments />}>
                        <Route path="discounts">
                          <Route
                            path=""
                            element={<Navigate replace to="all-discounts" />}
                          />
                          <Route
                            element={
                              <AppPageLayout
                                title="Discounts"
                                HeaderComponent={DiscountsHeader}
                                TabComponent={DiscountsTabs}
                                divider={true}
                              />
                            }>
                            <Route
                              path="all-discounts"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <DiscountsPage />
                                </Suspense>
                              }
                            />
                            {/*TODO: Hidden temporally <Route
                        path="discount-reasons"
                        element={
                          <Suspense fallback={<AppLoader />}>
                            <DiscountReasonsPage />
                          </Suspense>
                        }
                      /> */}
                          </Route>

                          <Route
                            path="create"
                            element={
                              <AppPageLayout
                                title={<CreateDiscountPageLayoutTitle />}
                                TabComponent={CreateDiscountsStepper}
                                divider={true}
                              />
                            }>
                            <Route
                              path=""
                              element={
                                <Navigate replace to="discount-details" />
                              }
                            />
                            <Route
                              path="discount-details"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <DiscountDetailsPage />
                                </Suspense>
                              }
                            />
                            <Route
                              path="applied-to"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <AppliedToPage />
                                </Suspense>
                              }
                            />
                            <Route
                              path="availability"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <AvailabilityPage />
                                </Suspense>
                              }
                            />
                            <Route
                              path="advanced"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <AdvancedPage />
                                </Suspense>
                              }
                            />
                          </Route>
                          <Route
                            path="create"
                            element={
                              <AppPageLayout
                                title={<CreateViewDiscountPageLayoutTitle />}
                                TabComponent={CreateEditDiscountsTabs}
                                divider={true}
                              />
                            }>
                            <Route
                              path="view"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <CreateDiscountsPage />
                                </Suspense>
                              }
                            />
                          </Route>
                          <Route
                            path="edit/:id"
                            element={
                              <AppPageLayout
                                title={<EditDiscountPageLayoutTitle />}
                                TabComponent={CreateEditDiscountsTabs}
                                divider={true}
                              />
                            }>
                            <Route
                              path=""
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <EditDiscountsPage />
                                </Suspense>
                              }
                            />
                          </Route>
                        </Route>
                      </Route>
                    </Route>

                    <Route path="reports">
                      <Route
                        path=""
                        element={
                          <>
                            <AppPageLayout title="Reports" />
                            <Suspense fallback={<AppLoader />}>
                              <ReportsHomePage />
                            </Suspense>
                          </>
                        }
                      />
                      {/* PRS */}
                      <Route
                        path="payment-reconciliation-summary"
                        element={
                          <Suspense fallback={<AppLoader />}>
                            <ReportsLayout
                              title="Payment Reconciliation Summary"
                              HorizontalTabs={PaymentReconciliationTabs}
                            />
                          </Suspense>
                        }>
                        <Route
                          path="detailed-summary"
                          element={
                            <Suspense fallback={<AppLoader />}>
                              <ReconciliationSummary />
                            </Suspense>
                          }
                        />
                        <Route
                          path="daily-summary"
                          element={
                            <Suspense fallback={<AppLoader />}>
                              <DailySummaryPage />
                            </Suspense>
                          }
                        />
                      </Route>
                      <Route element={<RequireStripePayments />}>
                        <Route
                          path="order-count-summary"
                          element={
                            <Suspense fallback={<AppLoader />}>
                              <OrderSummaryPage />
                            </Suspense>
                          }
                        />

                        <Route element={<RequireNonPos />}>
                          <Route
                            path="sales-by-menu-summary"
                            element={
                              <Suspense fallback={<AppLoader />}>
                                <SalesByMenuSummaryPage />
                              </Suspense>
                            }
                          />
                        </Route>
                        <Route
                          path="hourly-sales-summary"
                          element={
                            <Suspense fallback={<AppLoader />}>
                              <HourlySalesSummaryPage />
                            </Suspense>
                          }
                        />
                        <Route element={<RequirePOS />}>
                          <Route
                            path="receipts"
                            element={
                              <Suspense fallback={<AppLoader />}>
                                <ReportsReceiptsPage />
                              </Suspense>
                            }
                          />

                          <Route
                            path="shifts-summary"
                            element={
                              <Suspense fallback={<AppLoader />}>
                                <ReportsLayout
                                  title="Shifts"
                                  HorizontalTabs={ShiftTabs}
                                />
                              </Suspense>
                            }>
                            <Route
                              path="detailed"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <ShiftsSummaryPage />
                                </Suspense>
                              }
                            />

                            <Route
                              path="consolidated-shifts-summary"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <ConsolidatedShiftSummaryPage />
                                </Suspense>
                              }
                            />
                          </Route>
                          <Route path="shifts-summary/detailed">
                            <Route
                              path=":id"
                              element={
                                <Suspense fallback={<AppLoader />}>
                                  <SingleShiftSummaryPage />
                                </Suspense>
                              }
                            />
                          </Route>
                          <Route
                            path="sales-summary"
                            element={
                              <Suspense fallback={<AppLoader />}>
                                <SalesSummaryPage />
                              </Suspense>
                            }
                          />
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
              <Route path="*" element={<NotFoundPage />} />
            </SentryRoutes>
          </Box>
        </LocalizationProvider>
      </ConfirmationPopupProvider>
    </SnackbarProvider>
  );
};

export default App;
