import { Navigate, Outlet } from 'react-router';
import {
  ADYEN_LEGAL_ENTITY_STATUS,
  RESTAURANT_PAYMENT_PROVIDER,
} from '../constants';
import { useAppSelector } from '../hooks';
import { selectAuth } from '../redux/selectors/authSelectors';

type Props = {
  status: ADYEN_LEGAL_ENTITY_STATUS[];
  requirePosAndPaymentsOnly?: boolean;
};

const WithAdyenAccountStatus = ({
  status,
  requirePosAndPaymentsOnly = true,
}: Props) => {
  const { restaurant } = useAppSelector(selectAuth);

  const currentStatus = restaurant?.adyenAccount
    ?.status as ADYEN_LEGAL_ENTITY_STATUS;
  const isAdyenProvider =
    restaurant?.paymentProvider === RESTAURANT_PAYMENT_PROVIDER.ADYEN;

  // Check if current status matches any of the allowed statuses
  const isStatusValid = status.includes(currentStatus);

  // For overview page, we don't need POS and paymentsOnly check
  if (!requirePosAndPaymentsOnly && isAdyenProvider && isStatusValid) {
    return <Outlet />;
  }

  // For transaction-related pages, we need all conditions
  if (
    isAdyenProvider &&
    isStatusValid &&
    restaurant?.isPos &&
    restaurant?.paymentsOnly
  ) {
    return <Outlet />;
  }

  return <Navigate replace to="" />;
};

export default WithAdyenAccountStatus;
