import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { Colors } from '../../../theme/colors';

interface InfoProps {
  label: string;
  processedValue: string | number;
  refundsValue: string | number;
  netValue: string | number;
  processedQty?: string;
  refundsQty?: string;
  labelSx?: React.CSSProperties;
  valueSx?: React.CSSProperties;
  refundSx?: React.CSSProperties;
  qtySx?: React.CSSProperties;
  refreshing?: boolean;
}

const PaymentsRowInfo = ({
  refreshing = false,
  label,
  processedValue,
  refundsValue,
  netValue,
  processedQty,
  refundsQty,
  labelSx,
  valueSx,
  refundSx,
  qtySx,
}: InfoProps) => {
  return (
    <Box>
      <Grid container spacing={0}>
        <Grid item xs={3} sx={{ mb: 1 }}>
          <Typography
            sx={{
              color: Colors.black,
              fontWeight: 500,
              ...labelSx,
            }}>
            {label}
          </Typography>
        </Grid>

        <Grid
          item
          xs={1}
          sx={{ display: 'flex', justifyContent: 'right', mb: 1 }}>
          <Typography
            sx={{ color: Colors.fontColorPrimary, fontWeight: 500, ...qtySx }}>
            {refreshing ? '' : processedQty}
          </Typography>
        </Grid>

        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'right' }}>
          <Typography
            sx={{
              color: Colors.black,
              fontWeight: 600,
              mb: 1,
              ...valueSx,
            }}>
            {refreshing ? '' : processedValue}
          </Typography>
        </Grid>

        <Grid
          item
          xs={1}
          sx={{ display: 'flex', justifyContent: 'right', mb: 1 }}>
          <Typography
            sx={{
              color: Colors.fontColorPrimary,
              fontWeight: 500,
              ...qtySx,
              ...refundSx,
            }}>
            {refreshing ? '' : refundsQty}
          </Typography>
        </Grid>

        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'right' }}>
          <Typography
            sx={{
              color: Colors.black,
              fontWeight: 600,
              mb: 1,
              ...valueSx,
              ...refundSx,
            }}>
            {refreshing ? '' : refundsValue}
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Typography
            sx={{
              color: Colors.black,
              fontWeight: 600,
              mb: 1,
              ...valueSx,
            }}>
            {refreshing ? '' : netValue}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PaymentsRowInfo;
